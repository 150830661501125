/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiEmployeeCreationRequest,
    ApiEmployeeCreationRequestFromJSON,
    ApiEmployeeCreationRequestToJSON,
    ApiWorkshopEmployee,
    ApiWorkshopEmployeeFromJSON,
    ApiWorkshopEmployeeToJSON,
} from '../models';

export interface AddUserToWorkshopRequest {
    id: number;
    apiEmployeeCreationRequest: ApiEmployeeCreationRequest;
}

export interface GetEmployeeDetailsWithWorkshopRelationRequest {
    email: string;
    workshopId?: number;
}

/**
 * 
 */
export class WorkshopEmployeeApi extends runtime.BaseAPI {

    /**
     * Creates a new user as workshop employee
     */
    async addUserToWorkshopRaw(requestParameters: AddUserToWorkshopRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addUserToWorkshop.');
        }

        if (requestParameters.apiEmployeeCreationRequest === null || requestParameters.apiEmployeeCreationRequest === undefined) {
            throw new runtime.RequiredError('apiEmployeeCreationRequest','Required parameter requestParameters.apiEmployeeCreationRequest was null or undefined when calling addUserToWorkshop.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["read", "write"]);
        }

        const response = await this.request({
            path: `/workshops/{id}/employees`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiEmployeeCreationRequestToJSON(requestParameters.apiEmployeeCreationRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates a new user as workshop employee
     */
    async addUserToWorkshop(requestParameters: AddUserToWorkshopRequest, initOverrides?: RequestInit): Promise<void> {
        await this.addUserToWorkshopRaw(requestParameters, initOverrides);
    }

    /**
     * returns current workshop employee details
     */
    async getCurrentEmployeeDetailsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiWorkshopEmployee>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["read", "write"]);
        }

        const response = await this.request({
            path: `/employees/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiWorkshopEmployeeFromJSON(jsonValue));
    }

    /**
     * returns current workshop employee details
     */
    async getCurrentEmployeeDetails(initOverrides?: RequestInit): Promise<ApiWorkshopEmployee> {
        const response = await this.getCurrentEmployeeDetailsRaw(initOverrides);
        return await response.value();
    }

    /**
     * returns employee details and relation with specified workshop
     */
    async getEmployeeDetailsWithWorkshopRelationRaw(requestParameters: GetEmployeeDetailsWithWorkshopRelationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiWorkshopEmployee>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling getEmployeeDetailsWithWorkshopRelation.');
        }

        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.workshopId !== undefined) {
            queryParameters['workshopId'] = requestParameters.workshopId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["read", "write"]);
        }

        const response = await this.request({
            path: `/employees`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiWorkshopEmployeeFromJSON(jsonValue));
    }

    /**
     * returns employee details and relation with specified workshop
     */
    async getEmployeeDetailsWithWorkshopRelation(requestParameters: GetEmployeeDetailsWithWorkshopRelationRequest, initOverrides?: RequestInit): Promise<ApiWorkshopEmployee> {
        const response = await this.getEmployeeDetailsWithWorkshopRelationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
