import React, { ForwardedRef, useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Pagination from 'react-bootstrap/Pagination';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import './CaseList.css';
import Form from 'react-bootstrap/Form';
import Layout from '../../components/layout/Layout';
import {
  loadAssistanceCasesAction,
  loadClaimCasesAction,
  loadProgressStepDropdownAction,
} from './caseActions';
import { connect, ResolveThunks } from 'react-redux';
import { AppState } from '../../store/store';
import {
  ApiCaseProgress,
  ApiCaseStepEstimationType,
  ApiVehicleDetails,
  ApiWorkshopCase,
} from '../../api';
import { Loader } from '../../components/loader/Loader';
import { I18n, Translate } from 'react-redux-i18n';
import { BiCaretDown, BiFirstPage, BiLastPage } from 'react-icons/bi';
import { getParamFromUrl } from '../../common/utils/URLUtils';
import SmsLinkSelector, { SelectedSmsLinkType } from './SmsLinkSelector';
import { SendSmsLinkModal } from './SendSmsLinkModal';
import { Link } from 'react-router-dom';

export interface CaseRowProps {
  id: number;
  vehicle: ApiVehicleDetails;
  registrationTimeMs?: number;
  accidentTimeMs?: number;
  serviceName: string;
  testId: number;
  progress?: ApiCaseProgress;
}

export interface TableDataProps {
  tdContent: any;
  id: number;
}

export const CaseList: React.FC<CaseListProps> = ({
  language,
  claimTotalRows,
  claimCases,
  loadClaimCases,
  assistanceTotalRows,
  assistanceCases,
  loadAssistanceCases,
  searchPhrase,
  workshop,
  progressStepOptions,
  loadProgressStepFilterOptions,
  color,
}: CaseListProps) => {
  const TAB_CLAIMS = 'Claim';
  const TAB_ASSISTANCE = 'Assistance';
  const tab_query_name = 'tabKey';
  const completeCaseFilterKey = 'pickUpVehicleDone';
  const [tabKey, setTabKey] = useState(TAB_CLAIMS);
  const [loading, setLoading] = useState(true);
  const [filteredCases, setFilteredCases] = useState<ApiWorkshopCase[]>([]);
  const INITIAL_PAGE_SIZE = 16;
  const INITIAL_PAGE_NUMBER = 1;
  const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(INITIAL_PAGE_NUMBER);
  const [previousLicenseSearch, setPreviousLicenseSearch] = useState<string>();
  const [licenseSearch, setLicenseSearch] = useState<string>();
  const [progressStepId, setProgressStepId] = useState<number>();
  const [totalRows, setTotalRows] = useState(0);
  const tabVal = getParamFromUrl(tab_query_name);
  const [progressStepOptionsMap, setProgressStepOptionsMap] = useState<Map<number, string>>(
    new Map()
  );
  const [showSendSmsLinkPopup, setShowSmsLinkPopup] = useState(false);
  const [isSelectedClaimLink, setIsSelectedClaimLink] = useState(true);

  useEffect(() => {
    const optionsMap = progressStepOptions.reduce((mapAccumulator, obj) => {
      mapAccumulator.set(obj.id, obj.nameKey);
      return mapAccumulator;
    }, new Map());
    optionsMap.set(-1, completeCaseFilterKey);
    setProgressStepOptionsMap(optionsMap);
  }, [language, progressStepOptions]);

  useEffect(() => {
    switch (tabVal) {
      case TAB_ASSISTANCE.toLowerCase():
        handleTabChange(TAB_ASSISTANCE);
        break;
      case TAB_CLAIMS.toLowerCase():
        handleTabChange(TAB_CLAIMS);
        break;
      default:
        handleTabChange(tabKey);
        break;
    }
  }, [workshop]);

  useEffect(() => {
    filterCases(searchPhrase);
  }, [searchPhrase, claimCases, assistanceCases]);

  useEffect(() => {
    switch (tabKey) {
      case TAB_CLAIMS:
        setTotalRows(claimTotalRows);
        break;
      case TAB_ASSISTANCE:
        setTotalRows(assistanceTotalRows);
        break;
      default:
        setTotalRows(0);
        break;
    }
  }, [claimTotalRows, assistanceTotalRows]);

  const handleSmsLinkTypeSelection = (linkType: SelectedSmsLinkType) => {
    const isClaimLinkType = linkType === SelectedSmsLinkType.CLAIM;
    setIsSelectedClaimLink(isClaimLinkType);
    setShowSmsLinkPopup(true);
  };

  const loadCasesByTab = async (
    tab: string,
    pageNr: number,
    pageRows: number,
    license?: string,
    progressId?: number
  ) => {
    if (workshop) {
      setLoading(true);
      switch (tab) {
        case TAB_CLAIMS:
          await loadClaimCases(workshop, pageNr - 1, pageRows, license, progressId);
          break;
        case TAB_ASSISTANCE:
          await loadAssistanceCases(workshop, pageNr - 1, pageRows, license, progressId);
          break;
      }
      setLoading(false);
    }
  };

  const resetPageState = () => {
    setPageNumber(INITIAL_PAGE_NUMBER);
    setPageSize(INITIAL_PAGE_SIZE);
  };

  const handleTabChange = async (tab: string) => {
    setTabKey(tab);
    resetPageState();
    setProgressStepId(undefined);
    setLicenseSearch(undefined);
    loadCasesByTab(tab, INITIAL_PAGE_NUMBER, INITIAL_PAGE_SIZE);
    if (workshop && tab) {
      await loadProgressStepFilterOptions(tab.toUpperCase());
    }
  };

  const handlePageSizeChange = (size: number) => {
    if (pageNumber !== INITIAL_PAGE_NUMBER || pageSize <= totalRows || size < totalRows) {
      setPageNumber(INITIAL_PAGE_NUMBER);
      loadCasesByTab(tabKey, INITIAL_PAGE_NUMBER, size);
    }
    setPageSize(size);
  };

  const handlePageNumberChange = (number: number) => {
    setPageNumber(number);
    loadCasesByTab(tabKey, number, pageSize, licenseSearch, progressStepId);
  };

  const getCasesByTab = (tab: string) => {
    switch (tab) {
      case TAB_CLAIMS:
        return claimCases;
      case TAB_ASSISTANCE:
        return assistanceCases;
      default:
        return [];
    }
  };

  const filterCases = (input: string) => {
    let cases = getCasesByTab(tabKey);
    if (input && cases.length > 0) {
      cases = cases.filter((aCase) => aCase.vehicle.licensePlate.includes(input.toUpperCase()));
    }
    setFilteredCases(cases);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && previousLicenseSearch !== event.currentTarget.value) {
      setPreviousLicenseSearch(licenseSearch);
      setLicenseSearch(event.currentTarget.value || undefined);
      setPageNumber(INITIAL_PAGE_NUMBER);
      loadCasesByTab(
        tabKey,
        INITIAL_PAGE_NUMBER,
        pageSize,
        event.currentTarget.value || undefined,
        progressStepId
      );
    }
  };

  const CaseListHeader = () => {
    return (
      <thead>
        <tr>
          <th>
            <Translate value={'caseList.licensePlate'} />
            <br />
            <Form.Control
              id='licenseSearchInput'
              value={licenseSearch}
              autoFocus
              placeholder={I18n.t('filters.search')}
              onChange={(e) => setLicenseSearch(e.currentTarget.value)}
              data-testid={'licenseSearchFilter'}
              onKeyDown={handleKeyDown}
            />
          </th>
          <th>
            <Translate value={'caseList.vehicle'} />
          </th>
          <th>
            {tabKey === TAB_CLAIMS ? (
              <Translate value={'caseList.accidentDate'} />
            ) : (
              <Translate value={'caseList.registrationDate'} />
            )}
          </th>
          <th>
            <Translate value={'caseList.serviceName'} />
          </th>
          <th>
            <Translate value={'caseList.progress'} />
            <br />
            {
              <Dropdown data-testid='dropdown' onSelect={handleProgressStepSelection}>
                <Dropdown.Toggle id='dropdown-toggle'>
                  {progressStepId
                    ? I18n.t(`caseStatus.steps.${progressStepOptionsMap.get(progressStepId)}`)
                    : I18n.t('filters.filterByProgressStep')}
                </Dropdown.Toggle>

                <Dropdown.Menu popperConfig={{ strategy: 'fixed' }}>
                  <Dropdown.Item value={I18n.t('filters.filterByProgressStep')}>
                    {I18n.t('filters.filterByProgressStep')}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  {Array.from(progressStepOptionsMap.keys()).map((value) => {
                    return (
                      <Dropdown.Item key={value} eventKey={value} value={value}>
                        {progressStepOptionsMap &&
                          I18n.t(`caseStatus.steps.${progressStepOptionsMap.get(value)}`)}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            }
          </th>
        </tr>
      </thead>
    );
  };

  const handleProgressStepSelection = async (eventKey: string | null) => {
    if (eventKey) {
      setProgressStepId(parseInt(eventKey));
      loadCasesByTab(tabKey, INITIAL_PAGE_NUMBER, pageSize, licenseSearch, parseInt(eventKey));
      setPreviousLicenseSearch(undefined);
    } else {
      setProgressStepId(undefined);
      loadCasesByTab(tabKey, INITIAL_PAGE_NUMBER, pageSize, licenseSearch, undefined);
    }
  };

  const TableData = ({ tdContent, id }: TableDataProps) => {
    return (
      <td>
        <Link
          style={{ display: 'block', width: '100%', textDecoration: 'none', color: 'inherit' }}
          to={`/case-details/${id}`}
        >
          {tdContent}
        </Link>
      </td>
    );
  };

  const CaseRow = ({
    id,
    vehicle,
    registrationTimeMs,
    accidentTimeMs,
    serviceName,
    testId,
    progress,
  }: CaseRowProps) => {
    return (
      <tr data-testid={testId} className='case-row'>
        <TableData tdContent={vehicle.licensePlate} id={id} />
        <TableData
          tdContent={`${vehicle.mark} ${vehicle.model} ${
            vehicle.year !== undefined ? vehicle.year : ''
          }`}
          id={id}
        />
        <TableData
          tdContent={
            tabKey === TAB_CLAIMS
              ? accidentTimeMs && new Date(accidentTimeMs).toLocaleDateString('en-GB')
              : registrationTimeMs && new Date(registrationTimeMs).toLocaleDateString('en-GB')
          }
          id={id}
        />
        <TableData tdContent={serviceName} id={id} />
        <TableData
          tdContent={
            <>
              {progress?.completedTimeMs ? (
                <Translate value={`caseStatus.steps.${progress.nameKey}Done`} />
              ) : (
                <Translate value={`caseStatus.steps.${progress?.nameKey}`} />
              )}
              {progress?.estimationType != ApiCaseStepEstimationType.None ? (
                progress?.estimatedTimeMs != null ? (
                  ' (' + new Date(progress.estimatedTimeMs).toLocaleDateString('en-GB') + ')'
                ) : (
                  <Translate value={`caseList.toBeEstimated`} />
                )
              ) : (
                ''
              )}
            </>
          }
          id={id}
        />
      </tr>
    );
  };

  const CaseContent = () => {
    return loading ? (
      <Loader />
    ) : (
      <Table striped bordered hover responsive size='sm' className='mt-4'>
        <CaseListHeader />
        <tbody>
          {filteredCases && filteredCases.length > 0 ? (
            filteredCases.map((apiCase) => (
              <CaseRow key={apiCase.id} {...apiCase} testId={apiCase.id} />
            ))
          ) : (
            <tr>
              <td colSpan={6} className='text-center'>
                <Translate value={`caseList.no${tabKey}CasesFound`} />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  };

  const CaseTable = () => {
    return (
      <Tabs activeKey={tabKey} onSelect={(key) => key && handleTabChange(key)}>
        <Tab eventKey={TAB_CLAIMS} title={<Translate value={'caseList.tab.claims'} />}>
          <CaseContent />
        </Tab>
        <Tab
          eventKey={TAB_ASSISTANCE}
          title={<Translate value={'caseList.tab.assistanceRequests'} />}
        >
          <CaseContent />
        </Tab>
      </Tabs>
    );
  };

  const CasePagination = () => {
    interface RenderProps {
      children: React.ReactNode;
      onClick: (event: React.MouseEvent) => void;
    }

    const CustomToggle = React.forwardRef(
      ({ children, onClick }: RenderProps, ref: ForwardedRef<HTMLAnchorElement>) => (
        <a
          href='#'
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
          className='pagination-dropdown'
        >
          {children}
          <BiCaretDown />
        </a>
      )
    );
    CustomToggle.displayName = 'CustomDropdownToggle';
    const pageRows = totalRows < pageSize * pageNumber ? totalRows : pageSize * pageNumber;
    const maxPages = Math.ceil(totalRows / pageSize);

    return (
      <div className='d-flex justify-content-end'>
        <div>
          <Translate value='pagination.rowsPerPage' />:
        </div>
        <Dropdown
          className='pr-4 pl-1'
          onSelect={(eventKey) => eventKey && handlePageSizeChange(parseInt(eventKey))}
        >
          <Dropdown.Toggle as={CustomToggle}>{pageSize}</Dropdown.Toggle>
          <Dropdown.Menu align='right'>
            <Dropdown.Item eventKey='8'>8</Dropdown.Item>
            <Dropdown.Item eventKey='16'>16</Dropdown.Item>
            <Dropdown.Item eventKey='24'>24</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className='pr-4'>
          {(pageNumber - 1) * pageSize + 1}-{pageRows} of {totalRows}
        </div>
        <Pagination className='cases-pagination'>
          <Pagination.First disabled={pageNumber === 1} onClick={() => handlePageNumberChange(1)}>
            <BiFirstPage />
          </Pagination.First>
          <Pagination.Prev
            disabled={pageNumber === 1}
            onClick={() => handlePageNumberChange(pageNumber - 1)}
          />
          <Pagination.Next
            disabled={pageNumber === maxPages}
            onClick={() => handlePageNumberChange(pageNumber + 1)}
          />
          <Pagination.Last
            disabled={pageNumber === maxPages}
            onClick={() => handlePageNumberChange(maxPages)}
          >
            <BiLastPage />
          </Pagination.Last>
        </Pagination>
      </div>
    );
  };

  return (
    <Layout>
      <Row className='container-fluid'>
        <SmsLinkSelector color={color} onSelect={handleSmsLinkTypeSelection} />
        {showSendSmsLinkPopup && (
          <SendSmsLinkModal
            workshopId={workshop || 0}
            isClaimSmsLink={isSelectedClaimLink}
            show={showSendSmsLinkPopup}
            onClose={() => setShowSmsLinkPopup(false)}
          />
        )}
      </Row>
      <CaseTable />
      {totalRows > 0 && <CasePagination />}
    </Layout>
  );
};

const mapStateToProps = ({ i18n, cases, header, theme }: AppState) => ({
  language: i18n.locale,
  claimTotalRows: cases.claimCaseTotalCount,
  claimCases: cases.claimCases,
  assistanceTotalRows: cases.assistanceCaseTotalCount,
  assistanceCases: cases.assistanceCases,
  searchPhrase: header.searchPhrase,
  workshop: header.currentWorkshop,
  progressStepOptions: cases.dropDownOptions,
  color: theme.color,
});

const mapDispatchToProps = {
  loadAssistanceCases: loadAssistanceCasesAction,
  loadClaimCases: loadClaimCasesAction,
  loadProgressStepFilterOptions: loadProgressStepDropdownAction,
};

export type CaseListProps = ReturnType<typeof mapStateToProps> &
  ResolveThunks<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CaseList);
