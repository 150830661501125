/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiAccidentDetailsSection,
    ApiAccidentDetailsSectionFromJSON,
    ApiAccidentDetailsSectionFromJSONTyped,
    ApiAccidentDetailsSectionToJSON,
    ApiCaseComment,
    ApiCaseCommentFromJSON,
    ApiCaseCommentFromJSONTyped,
    ApiCaseCommentToJSON,
    ApiCaseDetailSection,
    ApiCaseDetailSectionFromJSON,
    ApiCaseDetailSectionFromJSONTyped,
    ApiCaseDetailSectionToJSON,
    ApiCaseStatus,
    ApiCaseStatusFromJSON,
    ApiCaseStatusFromJSONTyped,
    ApiCaseStatusToJSON,
    ApiCommunicationPreference,
    ApiCommunicationPreferenceFromJSON,
    ApiCommunicationPreferenceFromJSONTyped,
    ApiCommunicationPreferenceToJSON,
    ApiFile,
    ApiFileFromJSON,
    ApiFileFromJSONTyped,
    ApiFileToJSON,
    ApiNotifierSection,
    ApiNotifierSectionFromJSON,
    ApiNotifierSectionFromJSONTyped,
    ApiNotifierSectionToJSON,
    ApiOtherPartiesSection,
    ApiOtherPartiesSectionFromJSON,
    ApiOtherPartiesSectionFromJSONTyped,
    ApiOtherPartiesSectionToJSON,
    ApiVehicleInformationSection,
    ApiVehicleInformationSectionFromJSON,
    ApiVehicleInformationSectionFromJSONTyped,
    ApiVehicleInformationSectionToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiWorkshopCaseDetail
 */
export interface ApiWorkshopCaseDetail {
    /**
     * 
     * @type {string}
     * @memberof ApiWorkshopCaseDetail
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiWorkshopCaseDetail
     */
    licensePlate: string;
    /**
     * 
     * @type {ApiCaseStatus}
     * @memberof ApiWorkshopCaseDetail
     */
    status: ApiCaseStatus;
    /**
     * 
     * @type {ApiCaseDetailSection}
     * @memberof ApiWorkshopCaseDetail
     */
    caseDetailSection?: ApiCaseDetailSection;
    /**
     * 
     * @type {ApiVehicleInformationSection}
     * @memberof ApiWorkshopCaseDetail
     */
    vehicleInfoSection?: ApiVehicleInformationSection;
    /**
     * 
     * @type {ApiNotifierSection}
     * @memberof ApiWorkshopCaseDetail
     */
    notifierSection?: ApiNotifierSection;
    /**
     * 
     * @type {ApiAccidentDetailsSection}
     * @memberof ApiWorkshopCaseDetail
     */
    accidentDetailsSection?: ApiAccidentDetailsSection;
    /**
     * 
     * @type {Array<ApiOtherPartiesSection>}
     * @memberof ApiWorkshopCaseDetail
     */
    otherPartiesSection: Array<ApiOtherPartiesSection>;
    /**
     * 
     * @type {Array<ApiFile>}
     * @memberof ApiWorkshopCaseDetail
     */
    filesSection: Array<ApiFile>;
    /**
     * 
     * @type {Array<ApiFile>}
     * @memberof ApiWorkshopCaseDetail
     */
    documentSection?: Array<ApiFile>;
    /**
     * 
     * @type {ApiCommunicationPreference}
     * @memberof ApiWorkshopCaseDetail
     */
    communicationPreference?: ApiCommunicationPreference;
    /**
     * 
     * @type {ApiCaseComment}
     * @memberof ApiWorkshopCaseDetail
     */
    comment?: ApiCaseComment;
    /**
     * 
     * @type {boolean}
     * @memberof ApiWorkshopCaseDetail
     */
    isProgressNotificationsEnabled: boolean;
}

export function ApiWorkshopCaseDetailFromJSON(json: any): ApiWorkshopCaseDetail {
    return ApiWorkshopCaseDetailFromJSONTyped(json, false);
}

export function ApiWorkshopCaseDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiWorkshopCaseDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'licensePlate': json['licensePlate'],
        'status': ApiCaseStatusFromJSON(json['status']),
        'caseDetailSection': !exists(json, 'caseDetailSection') ? undefined : ApiCaseDetailSectionFromJSON(json['caseDetailSection']),
        'vehicleInfoSection': !exists(json, 'vehicleInfoSection') ? undefined : ApiVehicleInformationSectionFromJSON(json['vehicleInfoSection']),
        'notifierSection': !exists(json, 'notifierSection') ? undefined : ApiNotifierSectionFromJSON(json['notifierSection']),
        'accidentDetailsSection': !exists(json, 'accidentDetailsSection') ? undefined : ApiAccidentDetailsSectionFromJSON(json['accidentDetailsSection']),
        'otherPartiesSection': ((json['otherPartiesSection'] as Array<any>).map(ApiOtherPartiesSectionFromJSON)),
        'filesSection': ((json['filesSection'] as Array<any>).map(ApiFileFromJSON)),
        'documentSection': !exists(json, 'documentSection') ? undefined : ((json['documentSection'] as Array<any>).map(ApiFileFromJSON)),
        'communicationPreference': !exists(json, 'communicationPreference') ? undefined : ApiCommunicationPreferenceFromJSON(json['communicationPreference']),
        'comment': !exists(json, 'comment') ? undefined : ApiCaseCommentFromJSON(json['comment']),
        'isProgressNotificationsEnabled': json['isProgressNotificationsEnabled'],
    };
}

export function ApiWorkshopCaseDetailToJSON(value?: ApiWorkshopCaseDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'licensePlate': value.licensePlate,
        'status': ApiCaseStatusToJSON(value.status),
        'caseDetailSection': ApiCaseDetailSectionToJSON(value.caseDetailSection),
        'vehicleInfoSection': ApiVehicleInformationSectionToJSON(value.vehicleInfoSection),
        'notifierSection': ApiNotifierSectionToJSON(value.notifierSection),
        'accidentDetailsSection': ApiAccidentDetailsSectionToJSON(value.accidentDetailsSection),
        'otherPartiesSection': ((value.otherPartiesSection as Array<any>).map(ApiOtherPartiesSectionToJSON)),
        'filesSection': ((value.filesSection as Array<any>).map(ApiFileToJSON)),
        'documentSection': value.documentSection === undefined ? undefined : ((value.documentSection as Array<any>).map(ApiFileToJSON)),
        'communicationPreference': ApiCommunicationPreferenceToJSON(value.communicationPreference),
        'comment': ApiCaseCommentToJSON(value.comment),
        'isProgressNotificationsEnabled': value.isProgressNotificationsEnabled,
    };
}

