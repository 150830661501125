import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { saveCurrentLanguage } from '../../locale/languageActions';
import { AppState } from '../../store/store';
import { connect, ResolveThunks } from 'react-redux';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import { supportedLocales } from '../../locale/locale';

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  color,
  language,
  setLanguage,
}: LanguageSelectorProps) => {
  const handleChangeLanguage = async (eventKey: string | null) => {
    if (eventKey) {
      await setLanguage(eventKey as any);
    }
  };
  return (
    <>
      <style type='text/css'>
        {`.dropdown-item:active {
            background-color: ${color};
            color: #fff;
          }
          
          .btn-primary:focus {
            box-shadow: none !important;
          }
        `}
      </style>
      <Dropdown onSelect={handleChangeLanguage} title={language ?? 'en'}>
        <Dropdown.Toggle
          data-testid='language-selector'
          style={{ backgroundColor: color, borderColor: color }}
          title={language ?? 'en'}
        >
          {language ?? 'en'}
        </Dropdown.Toggle>
        <DropdownMenu alignRight>
          {Object.entries(supportedLocales).map((value: ArrayLike<string>) => (
            <Dropdown.Item eventKey={value[0]} value={value[0]} key={value[0]}>
              {value[1]}
            </Dropdown.Item>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

const mapDispatchToProp = {
  setLanguage: saveCurrentLanguage,
};

const mapStateToProp = ({ theme, i18n }: AppState) => ({
  language: i18n.locale,
  color: theme.color,
});

export type LanguageSelectorProps = ReturnType<typeof mapStateToProp> &
  ResolveThunks<typeof mapDispatchToProp>;

export default connect(mapStateToProp, mapDispatchToProp)(LanguageSelector);
