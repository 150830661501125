import Layout from '../../components/layout/Layout';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import './CaseDetail.css';
import CaseDetailSection from './case-detail-section/sections/CaseDetailSection';
import CaseDetailProgressSection from './case-progress-section/CaseDetailProgressSection';
import CaseDetailImageSection from './case-image-section/CaseDetailImageSection';
import CaseDetailDocumentSection from './case-document-section/CaseDetailDocumentSection';
import { CaseDetailsInfoTab } from './case-details-info-tab/CaseDetailsInfoTab';
import { connect, ResolveThunks } from 'react-redux';
import { AppState } from '../../store/store';
import { cancelCaseAction, fetchCaseDetailAction, removeCaseDetail } from './caseDetailActions';
import VehicleInfoSection from './case-detail-section/sections/VehicleInfoSection';
import NotifierInfoSection from './case-detail-section/sections/NotifierInfoSection';
import AccidentDetailsSection from './case-detail-section/sections/AccidentDetailsSection';
import OtherPartiesSection from './case-detail-section/sections/OtherPartiesSection';
import { useParams } from 'react-router-dom';
import { ApiCaseStatus, ApiCaseType } from '../../api/models';
import { push } from 'connected-react-router';
import { Loader } from '../../components/loader/Loader';
import { Translate } from 'react-redux-i18n';
import { CancelCaseModal } from './CancelCaseModal';
import CaseDetailCommentSection from './case-comment-section/CaseDetailCommentSection';
import CaseSettingsDropdown from './case-settings/CaseSettingsDropdown';

type CaseParam = {
  caseId: string;
};

export const CaseDetail: React.FC<CaseDetailProps> = ({
  caseDetail,
  clearCaseDetail,
  fetchCaseDetail,
  cancelCase,
  redirectToCasesByTab,
}: CaseDetailProps) => {
  const { caseId } = useParams<CaseParam>();

  const [isLoading, setIsLoading] = useState(true);
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    (async () => {
      await clearCaseDetail();
      if (caseId) {
        await fetchCaseDetail(Number(caseId));
        setIsLoading(false);
      }
    })();
  }, []);

  const isClaimCase = caseDetail.caseDetailSection?.type == ApiCaseType.ClaimInsurance;

  const CancelButton = () => (
    <>
      <Button
        className='ml-1 mr-3 mb-3'
        variant='outline-danger'
        onClick={() => setShowCancelModal(true)}
        data-testid='cancel-case-button'
      >
        <Translate value='caseDetail.cancelCase.button' className={'progress-section-btn-text'} />
      </Button>
      <CancelCaseModal
        showModal={showCancelModal}
        onSubmit={() => cancelCase(parseInt(caseId))}
        onClose={() => setShowCancelModal(false)}
      />
    </>
  );

  return isLoading ? (
    <Layout>
      <Loader />
    </Layout>
  ) : (
    <Layout>
      <Row className='h-auto case-detail' data-testid='detail-page'>
        <CaseDetailsInfoTab
          licensePlate={caseDetail.licensePlate}
          status={caseDetail.status}
          isClaimCase={isClaimCase}
          redirectToCasesByTab={redirectToCasesByTab}
        />
        <>
          {caseDetail.status == ApiCaseStatus.Pending && (
            <>
              <CaseSettingsDropdown />
              <CancelButton />
            </>
          )}
        </>
        <Col lg={6}>
          <CaseDetailSection caseSection={caseDetail.caseDetailSection} />
          <VehicleInfoSection vehicleInfoSection={caseDetail.vehicleInfoSection} />
          <NotifierInfoSection
            notifierInfoSection={caseDetail.notifierSection}
            communicationPreference={caseDetail.communicationPreference}
          />
          <AccidentDetailsSection accidentDetailsSection={caseDetail.accidentDetailsSection} />
          {isClaimCase && (
            <OtherPartiesSection otherPartiesSection={caseDetail.otherPartiesSection} />
          )}
        </Col>
        <Col lg={6}>
          <CaseDetailProgressSection />
          <CaseDetailCommentSection />
          <CaseDetailImageSection />
          {isClaimCase && <CaseDetailDocumentSection documents={caseDetail.documents} />}
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = ({ caseDetail }: AppState) => ({
  caseDetail,
});

const mapDispatchToProps = {
  clearCaseDetail: removeCaseDetail,
  fetchCaseDetail: fetchCaseDetailAction,
  cancelCase: cancelCaseAction,
  redirectToCasesByTab: (tabKey: string) => push(`/cases?tabKey=${tabKey}`),
};

export type CaseDetailProps = ResolveThunks<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CaseDetail);
