import {
  BASE_PATH,
  Configuration,
  WorkshopApi,
  WorkshopCaseApi,
  WorkshopCaseFileApi,
  WorkshopCaseProgressApi,
  WorkshopCaseSmsLinkApi,
  WorkshopEmployeeApi,
} from './api';
import authentication from './auth/authentication';

let basePath = BASE_PATH;

if (process.env.NODE_ENV !== 'production') {
  basePath = '/api/v1/workshop';
}

export const workshopConfiguration = new Configuration({
  basePath,
  accessToken: async () => {
    return `Bearer ${await authentication.getAccessToken()}`;
  },
});

export const workshopApi = new WorkshopApi(workshopConfiguration);
export const workshopCaseApi = new WorkshopCaseApi(workshopConfiguration);
export const workshopCaseFileApi = new WorkshopCaseFileApi(workshopConfiguration);
export const workshopEmployeeApi = new WorkshopEmployeeApi(workshopConfiguration);
export const workshopCaseSmsLinkApi = new WorkshopCaseSmsLinkApi(workshopConfiguration);
export const workshopCaseProgressApi = new WorkshopCaseProgressApi(workshopConfiguration);
export * from './api';
