import React from 'react';
import { Form } from 'react-bootstrap';
import './Forms.css';
import { ApiServiceInfo } from '../../api/models';

export interface Props {
  name?: string;
  label?: JSX.Element;
  excludeEmptyOption?: boolean;
  entries: ApiServiceInfo[];
  isInvalid?: boolean;
  validationError?: JSX.Element;
  testId?: string;
}

// eslint-disable-next-line react/display-name
export const SelectDropDown = React.forwardRef<HTMLSelectElement, Props>(
  (
    props: Props,
    ref:
      | ((instance: HTMLSelectElement | null) => void)
      | React.RefObject<HTMLSelectElement>
      | null
      | undefined
  ) => {
    const { label, excludeEmptyOption, entries, isInvalid, validationError, testId } = props;

    return (
      <Form.Group className='my-4'>
        <Form.Label className='form-ctrl-lbl'>{label}</Form.Label>
        <Form.Control as='select' {...props} ref={ref} data-testid={testId}>
          {!excludeEmptyOption && <option />}
          {entries.map((service: ApiServiceInfo) => (
            <option key={service.externalId} value={service.externalId}>
              {service.name}
            </option>
          ))}
        </Form.Control>
        {isInvalid && (
          <Form.Control.Feedback type='invalid'>{validationError}</Form.Control.Feedback>
        )}
      </Form.Group>
    );
  }
);
