/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiCaseProgress,
    ApiCaseProgressFromJSON,
    ApiCaseProgressFromJSONTyped,
    ApiCaseProgressToJSON,
    ApiLanguage,
    ApiLanguageFromJSON,
    ApiLanguageFromJSONTyped,
    ApiLanguageToJSON,
    ApiWorkshop1,
    ApiWorkshop1FromJSON,
    ApiWorkshop1FromJSONTyped,
    ApiWorkshop1ToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiCaseDetails
 */
export interface ApiCaseDetails {
    /**
     * 
     * @type {number}
     * @memberof ApiCaseDetails
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiCaseDetails
     */
    totalSteps: number;
    /**
     * 
     * @type {string}
     * @memberof ApiCaseDetails
     */
    licensePlate?: string;
    /**
     * 
     * @type {Array<ApiCaseProgress>}
     * @memberof ApiCaseDetails
     */
    progress?: Array<ApiCaseProgress>;
    /**
     * 
     * @type {ApiCaseProgress}
     * @memberof ApiCaseDetails
     */
    nextStep?: ApiCaseProgress;
    /**
     * 
     * @type {number}
     * @memberof ApiCaseDetails
     */
    serviceExternalId: number;
    /**
     * 
     * @type {string}
     * @memberof ApiCaseDetails
     */
    serviceName: string;
    /**
     * 
     * @type {ApiLanguage}
     * @memberof ApiCaseDetails
     */
    lang?: ApiLanguage;
    /**
     * 
     * @type {ApiWorkshop1}
     * @memberof ApiCaseDetails
     */
    workshop?: ApiWorkshop1;
}

export function ApiCaseDetailsFromJSON(json: any): ApiCaseDetails {
    return ApiCaseDetailsFromJSONTyped(json, false);
}

export function ApiCaseDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCaseDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'totalSteps': json['totalSteps'],
        'licensePlate': !exists(json, 'licensePlate') ? undefined : json['licensePlate'],
        'progress': !exists(json, 'progress') ? undefined : ((json['progress'] as Array<any>).map(ApiCaseProgressFromJSON)),
        'nextStep': !exists(json, 'nextStep') ? undefined : ApiCaseProgressFromJSON(json['nextStep']),
        'serviceExternalId': json['serviceExternalId'],
        'serviceName': json['serviceName'],
        'lang': !exists(json, 'lang') ? undefined : ApiLanguageFromJSON(json['lang']),
        'workshop': !exists(json, 'workshop') ? undefined : ApiWorkshop1FromJSON(json['workshop']),
    };
}

export function ApiCaseDetailsToJSON(value?: ApiCaseDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'totalSteps': value.totalSteps,
        'licensePlate': value.licensePlate,
        'progress': value.progress === undefined ? undefined : ((value.progress as Array<any>).map(ApiCaseProgressToJSON)),
        'nextStep': ApiCaseProgressToJSON(value.nextStep),
        'serviceExternalId': value.serviceExternalId,
        'serviceName': value.serviceName,
        'lang': ApiLanguageToJSON(value.lang),
        'workshop': ApiWorkshop1ToJSON(value.workshop),
    };
}

