import { ApiCaseDetails, ApiCaseProgress, ApiProgressStep, ApiWorkshopCase } from '../../api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CaseState {
  claimCaseTotalCount: number;
  claimCases: ApiWorkshopCase[];
  assistanceCaseTotalCount: number;
  assistanceCases: ApiWorkshopCase[];
  selectedCase?: number;
  selectedCaseProgress?: ApiCaseDetails;
  dropDownOptions: ApiProgressStep[];
}

const initialState: CaseState = {
  claimCaseTotalCount: 0,
  claimCases: [],
  assistanceCaseTotalCount: 0,
  assistanceCases: [],
  dropDownOptions: [],
};

const caseSlice = createSlice({
  name: 'cases',
  initialState,
  reducers: {
    setClaimCaseTotalCount: (state: CaseState, action: PayloadAction<number>) => {
      state.claimCaseTotalCount = action.payload;
    },
    addClaimCase: (state: CaseState, action: PayloadAction<ApiWorkshopCase>) => {
      state.claimCases.push(action.payload);
    },
    setAssistanceCaseTotalCount: (state: CaseState, action: PayloadAction<number>) => {
      state.assistanceCaseTotalCount = action.payload;
    },
    addAssistanceCase: (state: CaseState, action: PayloadAction<ApiWorkshopCase>) => {
      state.assistanceCases.push(action.payload);
    },
    setProgressFilterOptions: (state: CaseState, action: PayloadAction<Array<ApiProgressStep>>) => {
      state.dropDownOptions = action.payload;
    },
    clearCases: (state: CaseState) => {
      state.claimCaseTotalCount = initialState.claimCaseTotalCount;
      state.claimCases = initialState.claimCases;
      state.assistanceCaseTotalCount = initialState.assistanceCaseTotalCount;
      state.assistanceCases = initialState.assistanceCases;
      state.selectedCase = initialState.selectedCase;
      state.selectedCaseProgress = initialState.selectedCaseProgress;
    },
    clearDropDownOptions: (state: CaseState) => {
      state.dropDownOptions = initialState.dropDownOptions;
    },
    setSelectedCase: (state: CaseState, action: PayloadAction<number>) => {
      state.selectedCase = action.payload;
    },
    setSelectedCaseProgress: (
      state: CaseState,
      action: PayloadAction<ApiCaseDetails | undefined>
    ) => {
      state.selectedCaseProgress = action.payload;
    },
    setCaseProgressStatus: (
      state: CaseState,
      action: PayloadAction<{ progressId: number; estimatedTime?: Date }>
    ) => {
      const { progressId, estimatedTime } = action.payload;
      state.selectedCaseProgress?.progress?.forEach((step: ApiCaseProgress) => {
        if (progressId === step.id) {
          step.completedTimeMs = estimatedTime ? undefined : new Date().getTime();
          if (estimatedTime) {
            step.estimatedTimeMs = estimatedTime.getTime();
          }
        }
      });
    },
  },
});

const { actions, reducer } = caseSlice;

export const {
  setClaimCaseTotalCount,
  addClaimCase,
  setAssistanceCaseTotalCount,
  addAssistanceCase,
  setProgressFilterOptions,
  clearCases,
  clearDropDownOptions,
  setSelectedCase,
  setSelectedCaseProgress,
  setCaseProgressStatus,
} = actions;

export default reducer;
