import { Auth } from '@aws-amplify/auth';
import {
  AuthenticationError,
  CouldnotRetrieveSessionError,
  InvalidPasswordError,
  UserAlreadySignedUpError,
} from '../common/exceptions/AuthenticationError';
import {
  FAILED_SESSION_RETRIEVAL,
  LOGIN_FAILED,
  SIGNUP_FAILED,
  USER_ALREADY_SIGNED_UP,
} from '../common/exceptions/ErrorMessages';
import {
  INVALID_PASSWORD_EXCEPTION,
  USER_NAME_EXISTS_EXCEPTION,
} from '../common/exceptions/CognitoExceptionNames';

export interface AuthCredentials {
  email: string;
  password: string;
}

class Authentication {
  signIn = async (credentials: AuthCredentials): Promise<any> => {
    try {
      return await Auth.signIn(credentials.email, credentials.password);
    } catch (error: any) {
      throw new AuthenticationError(LOGIN_FAILED);
    }
  };

  signOut = async (): Promise<any> => {
    try {
      await Auth.signOut();
    } catch (error: any) {
      throw new AuthenticationError(error.message);
    }
  };

  getAccessToken = async (): Promise<any> => {
    try {
      const session = await Auth.currentSession();
      return session.getIdToken().getJwtToken();
    } catch (error) {
      throw new CouldnotRetrieveSessionError(FAILED_SESSION_RETRIEVAL);
    }
  };

  getExpiration = async (): Promise<any> => {
    try {
      const session = await Auth.currentSession();
      return session.getIdToken().getExpiration();
    } catch (error) {
      throw new AuthenticationError(FAILED_SESSION_RETRIEVAL);
    }
  };

  signUp = async (credentials: AuthCredentials): Promise<any> => {
    try {
      return await Auth.signUp({ username: credentials.email, password: credentials.password });
    } catch (error: any) {
      if (error.name === USER_NAME_EXISTS_EXCEPTION) {
        throw new UserAlreadySignedUpError(USER_ALREADY_SIGNED_UP);
      }
      if (error.name === INVALID_PASSWORD_EXCEPTION) {
        throw new InvalidPasswordError(error.message);
      } else {
        throw new AuthenticationError(SIGNUP_FAILED);
      }
    }
  };

  getUserInfo = async (): Promise<any> => {
    try {
      return await Auth.currentAuthenticatedUser();
    } catch (error) {
      throw new AuthenticationError('currentAuthenticatedUser');
    }
  };
}

export default new Authentication();
