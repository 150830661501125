/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiNotifier
 */
export interface ApiNotifier {
    /**
     * 
     * @type {string}
     * @memberof ApiNotifier
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiNotifier
     */
    phoneNumberPrefix?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiNotifier
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiNotifier
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiNotifier
     */
    personalCode?: string;
}

export function ApiNotifierFromJSON(json: any): ApiNotifier {
    return ApiNotifierFromJSONTyped(json, false);
}

export function ApiNotifierFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiNotifier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phoneNumberPrefix': !exists(json, 'phoneNumberPrefix') ? undefined : json['phoneNumberPrefix'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'personalCode': !exists(json, 'personalCode') ? undefined : json['personalCode'],
    };
}

export function ApiNotifierToJSON(value?: ApiNotifier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'phoneNumberPrefix': value.phoneNumberPrefix,
        'phoneNumber': value.phoneNumber,
        'email': value.email,
        'personalCode': value.personalCode,
    };
}

