import React, { ChangeEvent, KeyboardEvent, SyntheticEvent } from 'react';
import './FormInput.css';
import './Forms.css';
import { Form } from 'react-bootstrap';

export interface FormInputProps {
  label?: JSX.Element;
  controlId?: string;
  value?: string;
  onChangeHandler?: any;
  name?: string;
  onBlurHandler?: any;
  onEnterKeyHandler?: any;
  isInvalid?: boolean;
  validationError?: JSX.Element;
  testId?: string;
  cssClass?: string;
  min?: string;
  max?: string;
  formClass?: string;
}

interface Props extends FormInputProps {
  type: string;
}

// eslint-disable-next-line react/display-name
export const FormInput = React.forwardRef<HTMLInputElement, Props>(
  (
    props: Props,
    ref:
      | ((instance: HTMLInputElement | null) => void)
      | React.RefObject<HTMLInputElement>
      | null
      | undefined
  ) => {
    const {
      label,
      controlId,
      value,
      onChangeHandler,
      name,
      onBlurHandler,
      onEnterKeyHandler,
      isInvalid,
      validationError,
      testId,
      cssClass,
      min,
      max,
      type,
      formClass,
    } = props;

    return (
      <>
        <Form.Group controlId={controlId} className={`${formClass ? formClass : 'my-4'}`}>
          <Form.Label className='form-ctrl-lbl'>{label}</Form.Label>
          <Form.Control
            type={type}
            min={min}
            max={max}
            className={(isInvalid ? 'txt-input_invalid ' : 'txt-input') + ' ' + cssClass}
            name={name}
            value={value}
            onChange={(e: ChangeEvent) => onChangeHandler && onChangeHandler(e)}
            ref={ref}
            onBlur={(e: SyntheticEvent) => onBlurHandler && onBlurHandler(e)}
            onKeyPress={(e: KeyboardEvent) => {
              if (e.key === 'Enter') {
                onEnterKeyHandler && onEnterKeyHandler(e);
              }
            }}
            isInvalid={isInvalid}
            data-testid={testId}
          />
          {isInvalid && (
            <Form.Control.Feedback type='invalid'>{validationError}</Form.Control.Feedback>
          )}
        </Form.Group>
      </>
    );
  }
);
