/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiAssistanceCaseSmsLinkCreateRequest,
    ApiAssistanceCaseSmsLinkCreateRequestFromJSON,
    ApiAssistanceCaseSmsLinkCreateRequestToJSON,
    ApiClaimCaseSmsLinkCreateRequest,
    ApiClaimCaseSmsLinkCreateRequestFromJSON,
    ApiClaimCaseSmsLinkCreateRequestToJSON,
} from '../models';

export interface SendAssistanceSmsLinkToCustomerRequest {
    id: number;
    apiAssistanceCaseSmsLinkCreateRequest: ApiAssistanceCaseSmsLinkCreateRequest;
}

export interface SendClaimSmsLinkToCustomerRequest {
    id: number;
    apiClaimCaseSmsLinkCreateRequest: ApiClaimCaseSmsLinkCreateRequest;
}

/**
 * 
 */
export class WorkshopCaseSmsLinkApi extends runtime.BaseAPI {

    /**
     * generate a new SMS-link for an assistance request case
     */
    async sendAssistanceSmsLinkToCustomerRaw(requestParameters: SendAssistanceSmsLinkToCustomerRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sendAssistanceSmsLinkToCustomer.');
        }

        if (requestParameters.apiAssistanceCaseSmsLinkCreateRequest === null || requestParameters.apiAssistanceCaseSmsLinkCreateRequest === undefined) {
            throw new runtime.RequiredError('apiAssistanceCaseSmsLinkCreateRequest','Required parameter requestParameters.apiAssistanceCaseSmsLinkCreateRequest was null or undefined when calling sendAssistanceSmsLinkToCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["read", "write"]);
        }

        const response = await this.request({
            path: `/workshops/{id}/assistance-case-sms-link`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAssistanceCaseSmsLinkCreateRequestToJSON(requestParameters.apiAssistanceCaseSmsLinkCreateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * generate a new SMS-link for an assistance request case
     */
    async sendAssistanceSmsLinkToCustomer(requestParameters: SendAssistanceSmsLinkToCustomerRequest, initOverrides?: RequestInit): Promise<void> {
        await this.sendAssistanceSmsLinkToCustomerRaw(requestParameters, initOverrides);
    }

    /**
     * generate a new SMS-link for a a claim case
     */
    async sendClaimSmsLinkToCustomerRaw(requestParameters: SendClaimSmsLinkToCustomerRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sendClaimSmsLinkToCustomer.');
        }

        if (requestParameters.apiClaimCaseSmsLinkCreateRequest === null || requestParameters.apiClaimCaseSmsLinkCreateRequest === undefined) {
            throw new runtime.RequiredError('apiClaimCaseSmsLinkCreateRequest','Required parameter requestParameters.apiClaimCaseSmsLinkCreateRequest was null or undefined when calling sendClaimSmsLinkToCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["read", "write"]);
        }

        const response = await this.request({
            path: `/workshops/{id}/claim-case-sms-link`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiClaimCaseSmsLinkCreateRequestToJSON(requestParameters.apiClaimCaseSmsLinkCreateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * generate a new SMS-link for a a claim case
     */
    async sendClaimSmsLinkToCustomer(requestParameters: SendClaimSmsLinkToCustomerRequest, initOverrides?: RequestInit): Promise<void> {
        await this.sendClaimSmsLinkToCustomerRaw(requestParameters, initOverrides);
    }

}
