export const en = {
  errors: {
    tryAgain: 'Sorry, something went wrong. Try again later.',
    sessionExpiredLoginAgain: 'Session expired, please log in again.',
    savingCommentFailed: 'Failed to save comment. Please try again later.',
    savingProgressNotificationStatusFailed:
      'Failed to save progress notification status. Please try again later.',
  },
  login: {
    email: 'Email',
    password: 'Password',
    login: 'Login',
    errors: {
      email: 'Invalid Email',
      password: 'Invalid Password',
    },
  },
  caseList: {
    tab: {
      claims: 'Claims',
      assistanceRequests: 'Assistance requests',
    },
    licensePlate: 'License plate',
    vehicle: 'Vehicle',
    type: 'Type',
    registrationDate: 'Registration date',
    accidentDate: 'Accident date',
    serviceName: 'Service name',
    progress: 'Progress',
    noAssistanceCasesFound: 'No assistance cases found',
    noClaimCasesFound: 'No claim cases found',
    toBeEstimated: ' (to be estimated)',
  },
  caseDetail: {
    caseType: {
      CLAIM_INSURANCE: 'Claims',
      ROADSIDE_ASSISTANCE: 'Assistance requests',
    },
    caseStatus: {
      PENDING: 'PENDING',
      CANCELED: 'CANCELED',
      COMPLETED: 'COMPLETED',
    },
    cancelCase: {
      button: 'Cancel Case',
      modalTitle: 'Cancel Case',
      modalBody: 'Are you sure you want to cancel this case?',
      yes: 'YES',
      no: 'NO',
    },
    caseDetailSection: {
      title: 'CASE DETAILS',
      type: 'TYPE',
      registrationDate: 'REGISTRATION DATE',
      serviceName: 'SERVICE NAME',
    },
    vehicleInfoSection: {
      title: 'VEHICLE INFORMATION',
      licensePlate: 'LICENSE PLATE NR',
      mark: 'MARK',
      model: 'MODEL',
      year: 'YEAR',
    },
    notifierInfoSection: {
      title: 'NOTIFIER/DRIVER INFORMATION',
      name: 'NAME',
      phone: 'PHONE NUMBER',
      email: 'EMAIL',
      personalCode: 'PERSONAL CODE',
      driverName: 'DRIVER NAME',
      driverPhone: 'DRIVER PHONE',
      driverEmail: 'DRIVER EMAIL',
      driverPersonalCode: 'DRIVER PERSONAL CODE',
    },
    communicationPreference: {
      label: 'COMMUNICATION PREFERENCES',
      email: 'EMAIL',
      sms: 'SMS',
      emailAndSms: 'EMAIL & SMS',
    },
    accidentDetailsSection: {
      title: 'ACCIDENT DETAILS',
      incidentType: 'INCIDENT TYPE',
      date: 'DATE & TIME',
      location: 'LOCATION',
      description: 'DESCRIPTION',
    },
    otherPartiesSection: {
      title: 'OTHER PARTIES (OBJECTS)',
      description: 'DESCRIPTION',
      name: 'NAME',
      personalCode: 'PERSONAL CODE',
      phone: 'PHONE',
      email: 'EMAIL',
    },
    infoTab: {
      claims: 'Claims',
      assistance: 'Assistance Request',
    },
    documentsSection: {
      title: 'DOCUMENTS',
    },
    photos: 'PHOTOS',
    downloadAll: 'Download All',
    caseProgress: {
      title: 'CASE PROGRESS',
      markCompleted: 'Mark Completed',
      change: 'Change date',
    },
    noData: 'No data',
    commentsSection: {
      title: 'COMMENTS',
      save: 'Save',
      commentBoxPlaceholder: 'Type your comment and click save',
    },
    caseSettings: {
      button: 'Settings',
      progressNotifications: {
        label: 'Send progress notifications',
      },
    },
  },
  sendSmsLinkModal: {
    claimLinkModalTitle: 'Send claim link via SMS',
    assistanceLinkModalTitle: 'Send assistance request link via SMS',
    licensePlateLabel: 'Enter License Plate',
    phoneNumberLabel: 'Enter Phone Number',
    serviceSelectorLabel: 'Choose Service',
    sendSms: 'SEND SMS',
    claimLinkSentSuccessfully: 'Claim link sent successfully',
    assistanceLinkSentSuccessfully: 'Assistance link sent successfully',
    ifLinkWasntSentMessage: 'If for some reason you haven’t receive the link, try to resend it.',
    continue: 'CONTINUE',
  },
  header: {
    searchText: 'Search by license plate',
  },
  common: {
    required: 'Required field!',
  },
  caseStatus: {
    title: 'Case Status',
    registration: 'Registration nr.',
    policyInfo: 'Policy Info',
    steps: {
      caseRegistered: 'Case registered',
      caseRegisteredDone: 'Case registered',
      vehicleDeliveredToWorkshop: 'Vehicle delivered to workshop',
      vehicleDeliveredToWorkshopDone: 'Vehicle delivered to workshop',
      estimatedDiagnostics: 'Estimated diagnostics',
      estimatedDiagnosticsDone: 'Diagnostics done',
      preparingRepairEstimate: 'Preparing the repair estimate',
      preparingRepairEstimateDone: 'Repair estimate completed',
      confirmingRepairEstimate: 'Confirming the repair estimate',
      confirmingRepairEstimateDone: 'Repair estimate confirmed',
      estimatedArrivalOfSpareParts: 'Estimated arrival of spare parts',
      estimatedArrivalOfSparePartsDone: 'Spare parts arrived',
      repairingStarts: 'Repairing starts',
      repairingStartsDone: 'Repairing started',
      estimatedEndOfRepair: 'Estimated end of repair',
      estimatedEndOfRepairDone: 'Repair ended',
      pickUpVehicle: 'Please pick up vehicle',
      pickUpVehicleDone: 'Vehicle picked up',
    },
    nextStep: 'Your claim is being handled. Next step is',
    errors: {
      invalidOrExpiredLink: 'Link is expired or invalid',
    },
  },
  incidentType: {
    WINDSHIELD_DAMAGE: 'Windshield Damage',
    TRAFFIC_ACCIDENT: 'Traffic accident',
    HIT_WILD_ANIMAL: 'Hit Wild Animal',
    TECHNICAL_BREAKDOWN: 'Technical Breakdown',
    THEFT: 'Theft',
    VANDALISM_OR_FIRE: 'Vandalism or Fire',
    OTHER: 'Other',
    EMPTY_BATTERY: 'Empty Battery',
    RUN_OUT_OF_FUEL: 'Run out of fuel',
    FLAT_TIRES: 'Flat Tire',
    STUCK_IN_SAND_SNOW_MUD: 'Stuck in sand/snow/mud',
    DOOR_LOCKED: 'Doors Locked',
    UNKNOWN_TECHNICAL_ISSUE: 'Unknown technical issue',
  },
  pagination: {
    rowsPerPage: 'Rows per page',
  },
  logout: 'Logout',
  filters: {
    search: 'Search',
    filterByProgressStep: 'Filter by progress step',
  },
  addEmployee: {
    name: 'Full Name',
    phone: 'Phone Number',
    email: 'Email',
    password: 'Password',
    header: 'Add Employee',
    workshop: 'Workshop',
    saveEmployee: 'Save',
    success:
      'Employee successfully added and a confirmation email sent. After confirmation, employee will be able to sign in.',
    errors: {
      email: 'Invalid Email',
      phone: 'Invalid Phone Number',
      password: 'Invalid Password',
      name: 'Invalid Name',
      alreadyAssigned: 'Specified user is already assigned to this workshop.',
    },
  },
  refreshPageToApplyChangesPopup: {
    refresh: 'Refresh',
    refreshPageToSeeUpdates:
      'We have updated the page. Please refresh the page to see the latest changes.',
  },
  smsLinkSelector: {
    smsLink: 'SMS LINK',
    claimLink: 'Claim',
    assistanceLink: 'Roadside assistance',
  },
};
