import React from 'react';
import './CaseDetailSectionTitle.css';

export interface CaseDetailsSectionTitleProps {
  title: JSX.Element;
  showDivider?: boolean | undefined;
}

const CaseDetailSectionTitle: React.FC<CaseDetailsSectionTitleProps> = ({
  title,
  showDivider,
}: CaseDetailsSectionTitleProps) => {
  return showDivider ? (
    <h6 className='title'>{title}</h6>
  ) : (
    <div className='d-flex justify-content-start mb-2 pr-2'>
      <span className='title bg-white'>{title}</span>
      &nbsp;&nbsp;
      <div className='flex-grow-1'>
        <div className='title-divider' />
      </div>
    </div>
  );
};

export default CaseDetailSectionTitle;
