/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiCaseComment,
    ApiCaseCommentFromJSON,
    ApiCaseCommentToJSON,
    ApiCaseProgressNotificationStatus,
    ApiCaseProgressNotificationStatusFromJSON,
    ApiCaseProgressNotificationStatusToJSON,
    ApiCaseUpdate,
    ApiCaseUpdateFromJSON,
    ApiCaseUpdateToJSON,
    ApiWorkshopCaseDetail,
    ApiWorkshopCaseDetailFromJSON,
    ApiWorkshopCaseDetailToJSON,
} from '../models';

export interface GetCaseDetailsByIdRequest {
    caseId: number;
}

export interface UpdateCaseRequest {
    caseId: number;
    apiCaseUpdate: ApiCaseUpdate;
}

export interface UpdateCaseCommentRequest {
    caseId: number;
    apiCaseComment: ApiCaseComment;
}

export interface UpdateCaseProgressNotificationStatusRequest {
    caseId: number;
    apiCaseProgressNotificationStatus: ApiCaseProgressNotificationStatus;
}

/**
 * 
 */
export class WorkshopCaseApi extends runtime.BaseAPI {

    /**
     * returns case details by id
     */
    async getCaseDetailsByIdRaw(requestParameters: GetCaseDetailsByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiWorkshopCaseDetail>> {
        if (requestParameters.caseId === null || requestParameters.caseId === undefined) {
            throw new runtime.RequiredError('caseId','Required parameter requestParameters.caseId was null or undefined when calling getCaseDetailsById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["read", "write"]);
        }

        const response = await this.request({
            path: `/cases/{caseId}`.replace(`{${"caseId"}}`, encodeURIComponent(String(requestParameters.caseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiWorkshopCaseDetailFromJSON(jsonValue));
    }

    /**
     * returns case details by id
     */
    async getCaseDetailsById(requestParameters: GetCaseDetailsByIdRequest, initOverrides?: RequestInit): Promise<ApiWorkshopCaseDetail> {
        const response = await this.getCaseDetailsByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update case
     */
    async updateCaseRaw(requestParameters: UpdateCaseRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.caseId === null || requestParameters.caseId === undefined) {
            throw new runtime.RequiredError('caseId','Required parameter requestParameters.caseId was null or undefined when calling updateCase.');
        }

        if (requestParameters.apiCaseUpdate === null || requestParameters.apiCaseUpdate === undefined) {
            throw new runtime.RequiredError('apiCaseUpdate','Required parameter requestParameters.apiCaseUpdate was null or undefined when calling updateCase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["read", "write"]);
        }

        const response = await this.request({
            path: `/cases/{caseId}`.replace(`{${"caseId"}}`, encodeURIComponent(String(requestParameters.caseId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiCaseUpdateToJSON(requestParameters.apiCaseUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * update case
     */
    async updateCase(requestParameters: UpdateCaseRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateCaseRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateCaseCommentRaw(requestParameters: UpdateCaseCommentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.caseId === null || requestParameters.caseId === undefined) {
            throw new runtime.RequiredError('caseId','Required parameter requestParameters.caseId was null or undefined when calling updateCaseComment.');
        }

        if (requestParameters.apiCaseComment === null || requestParameters.apiCaseComment === undefined) {
            throw new runtime.RequiredError('apiCaseComment','Required parameter requestParameters.apiCaseComment was null or undefined when calling updateCaseComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["read", "write"]);
        }

        const response = await this.request({
            path: `/cases/{caseId}/comment`.replace(`{${"caseId"}}`, encodeURIComponent(String(requestParameters.caseId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiCaseCommentToJSON(requestParameters.apiCaseComment),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateCaseComment(requestParameters: UpdateCaseCommentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateCaseCommentRaw(requestParameters, initOverrides);
    }

    /**
     * update case progress notification status
     */
    async updateCaseProgressNotificationStatusRaw(requestParameters: UpdateCaseProgressNotificationStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.caseId === null || requestParameters.caseId === undefined) {
            throw new runtime.RequiredError('caseId','Required parameter requestParameters.caseId was null or undefined when calling updateCaseProgressNotificationStatus.');
        }

        if (requestParameters.apiCaseProgressNotificationStatus === null || requestParameters.apiCaseProgressNotificationStatus === undefined) {
            throw new runtime.RequiredError('apiCaseProgressNotificationStatus','Required parameter requestParameters.apiCaseProgressNotificationStatus was null or undefined when calling updateCaseProgressNotificationStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["read", "write"]);
        }

        const response = await this.request({
            path: `/cases/{caseId}/progress-notification-status`.replace(`{${"caseId"}}`, encodeURIComponent(String(requestParameters.caseId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiCaseProgressNotificationStatusToJSON(requestParameters.apiCaseProgressNotificationStatus),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * update case progress notification status
     */
    async updateCaseProgressNotificationStatus(requestParameters: UpdateCaseProgressNotificationStatusRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateCaseProgressNotificationStatusRaw(requestParameters, initOverrides);
    }

}
