import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '../store/store';
import { Redirect, Route, Switch } from 'react-router';
import WorkshopLogin from '../pages/login/WorkshopLogin';
import CaseList from '../pages/cases/CaseList';
import PrivateRoute from './PrivateRoute';
import CaseDetail from '../pages/case-detail/CaseDetail';
import AddEmployee from '../pages/admin/AddEmployee';

export const Router: React.FC = () => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path='/login' component={WorkshopLogin} />
        <PrivateRoute path='/cases' component={CaseList} />
        <PrivateRoute path='/case-details/:caseId' component={CaseDetail} />
        <PrivateRoute path='/add-employee' roles={['workshop-admins']} component={AddEmployee} />
        <Redirect from='/' to='/cases' />
        <Redirect from='*' to='/' />
      </Switch>
    </ConnectedRouter>
  );
};
