/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiCaseDetails,
    ApiCaseDetailsFromJSON,
    ApiCaseDetailsToJSON,
    ApiCaseProgressRequest,
    ApiCaseProgressRequestFromJSON,
    ApiCaseProgressRequestToJSON,
} from '../models';

export interface GetProgressByCaseRequest {
    caseId: number;
}

export interface UpdateCaseProgressRequest {
    caseId: number;
    progressId: number;
    apiCaseProgressRequest?: ApiCaseProgressRequest;
}

/**
 * 
 */
export class WorkshopCaseProgressApi extends runtime.BaseAPI {

    /**
     * gets case details by case id
     */
    async getProgressByCaseRaw(requestParameters: GetProgressByCaseRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiCaseDetails>> {
        if (requestParameters.caseId === null || requestParameters.caseId === undefined) {
            throw new runtime.RequiredError('caseId','Required parameter requestParameters.caseId was null or undefined when calling getProgressByCase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["read", "write"]);
        }

        const response = await this.request({
            path: `/cases/{caseId}/progress`.replace(`{${"caseId"}}`, encodeURIComponent(String(requestParameters.caseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiCaseDetailsFromJSON(jsonValue));
    }

    /**
     * gets case details by case id
     */
    async getProgressByCase(requestParameters: GetProgressByCaseRequest, initOverrides?: RequestInit): Promise<ApiCaseDetails> {
        const response = await this.getProgressByCaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update case progress by case id
     */
    async updateCaseProgressRaw(requestParameters: UpdateCaseProgressRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.caseId === null || requestParameters.caseId === undefined) {
            throw new runtime.RequiredError('caseId','Required parameter requestParameters.caseId was null or undefined when calling updateCaseProgress.');
        }

        if (requestParameters.progressId === null || requestParameters.progressId === undefined) {
            throw new runtime.RequiredError('progressId','Required parameter requestParameters.progressId was null or undefined when calling updateCaseProgress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["read", "write"]);
        }

        const response = await this.request({
            path: `/cases/{caseId}/progresses/{progressId}`.replace(`{${"caseId"}}`, encodeURIComponent(String(requestParameters.caseId))).replace(`{${"progressId"}}`, encodeURIComponent(String(requestParameters.progressId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ApiCaseProgressRequestToJSON(requestParameters.apiCaseProgressRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * update case progress by case id
     */
    async updateCaseProgress(requestParameters: UpdateCaseProgressRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateCaseProgressRaw(requestParameters, initOverrides);
    }

}
