import React from 'react';

export interface SectionHeaderProps {
  text: string | any;
  testId?: string;
  cssClass?: string;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  text,
  testId,
  cssClass,
}: SectionHeaderProps) => {
  const testid = testId ? { 'data-testid': testId } : {};
  return (
    <h5 className={'text-center font-weight-bold p-2 my-2 ' + cssClass} {...testid}>
      {text}
    </h5>
  );
};
