/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiEmployeeCreationRequest
 */
export interface ApiEmployeeCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiEmployeeCreationRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ApiEmployeeCreationRequest
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof ApiEmployeeCreationRequest
     */
    email: string;
}

export function ApiEmployeeCreationRequestFromJSON(json: any): ApiEmployeeCreationRequest {
    return ApiEmployeeCreationRequestFromJSONTyped(json, false);
}

export function ApiEmployeeCreationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiEmployeeCreationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'phone': json['phone'],
        'email': json['email'],
    };
}

export function ApiEmployeeCreationRequestToJSON(value?: ApiEmployeeCreationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'phone': value.phone,
        'email': value.email,
    };
}

