/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiCaseStatus,
    ApiCaseStatusFromJSON,
    ApiCaseStatusToJSON,
    ApiProgressStepsList,
    ApiProgressStepsListFromJSON,
    ApiProgressStepsListToJSON,
    ApiServiceInfo,
    ApiServiceInfoFromJSON,
    ApiServiceInfoToJSON,
    ApiWorkshopCasePage,
    ApiWorkshopCasePageFromJSON,
    ApiWorkshopCasePageToJSON,
} from '../models';

export interface GetAvailableServicesByWorkshopRequest {
    id: number;
}

export interface GetProgressStepByCaseTypeRequest {
    caseType: string;
}

export interface GetWorkshopAssistanceCasesRequest {
    id: number;
    caseStatus: ApiCaseStatus;
    pageNumber?: number;
    pageSize?: number;
    licensePlate?: string;
    progressStepId?: number;
}

export interface GetWorkshopClaimCasesRequest {
    id: number;
    caseStatus: ApiCaseStatus;
    pageNumber?: number;
    pageSize?: number;
    licensePlate?: string;
    progressStepId?: number;
}

/**
 * 
 */
export class WorkshopApi extends runtime.BaseAPI {

    /**
     * get list of available services by given workshop
     */
    async getAvailableServicesByWorkshopRaw(requestParameters: GetAvailableServicesByWorkshopRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiServiceInfo>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAvailableServicesByWorkshop.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["read", "write"]);
        }

        const response = await this.request({
            path: `/workshops/{id}/available-services`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiServiceInfoFromJSON));
    }

    /**
     * get list of available services by given workshop
     */
    async getAvailableServicesByWorkshop(requestParameters: GetAvailableServicesByWorkshopRequest, initOverrides?: RequestInit): Promise<Array<ApiServiceInfo>> {
        const response = await this.getAvailableServicesByWorkshopRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * returns a list of progress steps
     */
    async getProgressStepByCaseTypeRaw(requestParameters: GetProgressStepByCaseTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiProgressStepsList>> {
        if (requestParameters.caseType === null || requestParameters.caseType === undefined) {
            throw new runtime.RequiredError('caseType','Required parameter requestParameters.caseType was null or undefined when calling getProgressStepByCaseType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["read", "write"]);
        }

        const response = await this.request({
            path: `/progress-steps/{case-type}`.replace(`{${"case-type"}}`, encodeURIComponent(String(requestParameters.caseType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiProgressStepsListFromJSON(jsonValue));
    }

    /**
     * returns a list of progress steps
     */
    async getProgressStepByCaseType(requestParameters: GetProgressStepByCaseTypeRequest, initOverrides?: RequestInit): Promise<ApiProgressStepsList> {
        const response = await this.getProgressStepByCaseTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * returns a list of workshop\'s roadside assistance cases
     */
    async getWorkshopAssistanceCasesRaw(requestParameters: GetWorkshopAssistanceCasesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiWorkshopCasePage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getWorkshopAssistanceCases.');
        }

        if (requestParameters.caseStatus === null || requestParameters.caseStatus === undefined) {
            throw new runtime.RequiredError('caseStatus','Required parameter requestParameters.caseStatus was null or undefined when calling getWorkshopAssistanceCases.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.licensePlate !== undefined) {
            queryParameters['licensePlate'] = requestParameters.licensePlate;
        }

        if (requestParameters.progressStepId !== undefined) {
            queryParameters['progressStepId'] = requestParameters.progressStepId;
        }

        if (requestParameters.caseStatus !== undefined) {
            queryParameters['caseStatus'] = requestParameters.caseStatus;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["read", "write"]);
        }

        const response = await this.request({
            path: `/workshops/{id}/assistance-cases`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiWorkshopCasePageFromJSON(jsonValue));
    }

    /**
     * returns a list of workshop\'s roadside assistance cases
     */
    async getWorkshopAssistanceCases(requestParameters: GetWorkshopAssistanceCasesRequest, initOverrides?: RequestInit): Promise<ApiWorkshopCasePage> {
        const response = await this.getWorkshopAssistanceCasesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * returns a list of workshop\'s claim cases
     */
    async getWorkshopClaimCasesRaw(requestParameters: GetWorkshopClaimCasesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiWorkshopCasePage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getWorkshopClaimCases.');
        }

        if (requestParameters.caseStatus === null || requestParameters.caseStatus === undefined) {
            throw new runtime.RequiredError('caseStatus','Required parameter requestParameters.caseStatus was null or undefined when calling getWorkshopClaimCases.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.licensePlate !== undefined) {
            queryParameters['licensePlate'] = requestParameters.licensePlate;
        }

        if (requestParameters.progressStepId !== undefined) {
            queryParameters['progressStepId'] = requestParameters.progressStepId;
        }

        if (requestParameters.caseStatus !== undefined) {
            queryParameters['caseStatus'] = requestParameters.caseStatus;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["read", "write"]);
        }

        const response = await this.request({
            path: `/workshops/{id}/claim-cases`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiWorkshopCasePageFromJSON(jsonValue));
    }

    /**
     * returns a list of workshop\'s claim cases
     */
    async getWorkshopClaimCases(requestParameters: GetWorkshopClaimCasesRequest, initOverrides?: RequestInit): Promise<ApiWorkshopCasePage> {
        const response = await this.getWorkshopClaimCasesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
