import { Dispatch } from 'redux';
import {
  clearSearchPhrase,
  setCurrentWorkshop,
  setEmployee,
  setSearchPhrase,
} from './headerReducer';
import { workshopEmployeeApi } from '../../apis';
import store from '../../store/store';
import { CouldnotRetrieveSessionError } from '../../common/exceptions/AuthenticationError';
import { push } from 'connected-react-router';

export const updateSearchPhraseAction =
  (searchPhrase: string) =>
  (dispatch: Dispatch): void => {
    !searchPhrase
      ? dispatch(clearSearchPhrase())
      : dispatch(setSearchPhrase(searchPhrase.toUpperCase()));
  };

export const getLoggedInEmployeeAction =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const employeeDetails = await workshopEmployeeApi.getCurrentEmployeeDetails();
      dispatch(setEmployee(employeeDetails));
      const currentWorkshop = store.getState().header.currentWorkshop;
      if (
        employeeDetails &&
        employeeDetails.workshops &&
        employeeDetails.workshops.length > 0 &&
        (!currentWorkshop ||
          !employeeDetails.workshops.some((workshop) => workshop.id === currentWorkshop))
      ) {
        dispatch(setCurrentWorkshop(employeeDetails.workshops[0].id));
      }
    } catch (e) {
      if (e instanceof CouldnotRetrieveSessionError) {
        dispatch(push('/login?expired=true'));
      } else {
        dispatch(setEmployee());
        dispatch(setCurrentWorkshop());
      }
    }
  };

export const setCurrentWorkshopAction =
  (id: number | undefined) =>
  (dispatch: Dispatch): void => {
    dispatch(setCurrentWorkshop(id));
  };
