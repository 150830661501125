export class AuthenticationError extends Error {
  constructor(m: string) {
    super(m);
    Object.setPrototypeOf(this, AuthenticationError.prototype);
  }
}

export class CouldnotRetrieveSessionError extends AuthenticationError {
  constructor(m: string) {
    super(m);
    Object.setPrototypeOf(this, CouldnotRetrieveSessionError.prototype);
  }
}

export class UserAlreadySignedUpError extends AuthenticationError {
  constructor(m: string) {
    super(m);

    Object.setPrototypeOf(this, UserAlreadySignedUpError.prototype);
  }
}

export class InvalidPasswordError extends AuthenticationError {
  constructor(m: string) {
    super(m);

    Object.setPrototypeOf(this, InvalidPasswordError.prototype);
  }
}
