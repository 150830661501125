import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ApiAccidentDetailsSection,
  ApiCaseDetailSection,
  ApiCaseStatus,
  ApiCommunicationPreference,
  ApiFile,
  ApiNotifierSection,
  ApiOtherPartiesSection,
  ApiVehicleInformationSection,
  ApiWorkshopCaseDetail,
} from '../../api';
import { ApiCaseComment } from '../../api/models';

export interface CaseDetailState {
  id: string | undefined;
  licensePlate: string;
  status: ApiCaseStatus;
  caseDetailSection: ApiCaseDetailSection | undefined;
  vehicleInfoSection: ApiVehicleInformationSection | undefined;
  notifierSection: ApiNotifierSection | undefined;
  accidentDetailsSection: ApiAccidentDetailsSection | undefined;
  otherPartiesSection: ApiOtherPartiesSection[];
  filesSection: ApiFile[];
  documents: ApiFile[];
  communicationPreference: ApiCommunicationPreference | undefined;
  comment: ApiCaseComment | undefined;
  isProgressNotificationsEnabled: boolean;
}

const initialState: CaseDetailState = {
  id: undefined,
  licensePlate: '',
  status: ApiCaseStatus.Pending,
  caseDetailSection: undefined,
  vehicleInfoSection: undefined,
  notifierSection: undefined,
  accidentDetailsSection: undefined,
  otherPartiesSection: [],
  filesSection: [],
  documents: [],
  communicationPreference: undefined,
  comment: undefined,
  isProgressNotificationsEnabled: true,
};

const caseDetailSlice = createSlice({
  name: 'caseDetail',
  initialState,
  reducers: {
    setCaseDetail: (state: CaseDetailState, action: PayloadAction<ApiWorkshopCaseDetail>) => {
      if (action.payload) {
        state.id = action.payload.id;
        state.licensePlate = action.payload.licensePlate;
        state.status = action.payload.status;
        state.caseDetailSection = action.payload.caseDetailSection;
        state.vehicleInfoSection = action.payload.vehicleInfoSection;
        state.notifierSection = action.payload.notifierSection;
        state.accidentDetailsSection = action.payload.accidentDetailsSection;
        state.otherPartiesSection = action.payload.otherPartiesSection;
        state.filesSection = action.payload.filesSection;
        state.documents = action.payload.documentSection
          ? action.payload.documentSection
          : state.documents;
        state.communicationPreference = action.payload.communicationPreference;
        state.comment = action.payload.comment;
        state.isProgressNotificationsEnabled = action.payload.isProgressNotificationsEnabled;
      }
    },

    clearCaseDetail: (state: CaseDetailState) => {
      state.id = initialState.id;
      state.licensePlate = initialState.licensePlate;
      state.status = initialState.status;
      state.caseDetailSection = initialState.caseDetailSection;
      state.vehicleInfoSection = initialState.vehicleInfoSection;
      state.notifierSection = initialState.notifierSection;
      state.accidentDetailsSection = initialState.accidentDetailsSection;
      state.otherPartiesSection = initialState.otherPartiesSection;
      state.filesSection = initialState.filesSection;
      state.documents = initialState.documents;
      state.communicationPreference = initialState.communicationPreference;
      state.comment = initialState.comment;
      state.isProgressNotificationsEnabled = initialState.isProgressNotificationsEnabled;
    },

    setCaseStatus: (state: CaseDetailState, action: PayloadAction<ApiCaseStatus>) => {
      state.status = action.payload;
    },

    setCaseComment: (state: CaseDetailState, action: PayloadAction<ApiCaseComment>) => {
      state.comment = action.payload;
    },

    setProgressNotificationSendingStatus: (
      state: CaseDetailState,
      action: PayloadAction<boolean>
    ) => {
      state.isProgressNotificationsEnabled = action.payload;
    },
  },
});

const { actions, reducer } = caseDetailSlice;

export const {
  setCaseDetail,
  clearCaseDetail,
  setCaseStatus,
  setCaseComment,
  setProgressNotificationSendingStatus,
} = actions;

export default reducer;
