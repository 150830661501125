import {
  ApiServiceInfo,
  SendClaimSmsLinkToCustomerRequest,
  workshopApi,
  workshopCaseSmsLinkApi,
  SendAssistanceSmsLinkToCustomerRequest,
} from '../../apis';
import { CouldnotRetrieveSessionError } from '../../common/exceptions/AuthenticationError';

export const sendClaimSmsLinkAction = async (
  workshopId: number,
  phoneNumberPrefix: string,
  phoneNumber: string,
  licensePlate: string,
  serviceExternalId: number
): Promise<void> => {
  const request: SendClaimSmsLinkToCustomerRequest = {
    id: workshopId,
    apiClaimCaseSmsLinkCreateRequest: {
      phoneNumberPrefix: phoneNumberPrefix,
      phoneNumber: phoneNumber,
      licensePlate: licensePlate,
      serviceExternalId: serviceExternalId,
    },
  };
  try {
    await workshopCaseSmsLinkApi.sendClaimSmsLinkToCustomer(request);
  } catch (e) {
    if (e instanceof CouldnotRetrieveSessionError) {
      window.location.assign('/login?expired=true');
    } else throw e;
  }
};

export const sendAssistanceSmsLinkAction = async (
  workshopId: number,
  phoneNumberPrefix: string,
  phoneNumber: string,
  licensePlate: string,
  serviceExternalId: number
): Promise<void> => {
  const request: SendAssistanceSmsLinkToCustomerRequest = {
    id: workshopId,
    apiAssistanceCaseSmsLinkCreateRequest: {
      phoneNumberPrefix: phoneNumberPrefix,
      phoneNumber: phoneNumber,
      licensePlate: licensePlate,
      serviceExternalId: serviceExternalId,
    },
  };
  try {
    await workshopCaseSmsLinkApi.sendAssistanceSmsLinkToCustomer(request);
  } catch (e) {
    if (e instanceof CouldnotRetrieveSessionError) {
      window.location.assign('/login?expired=true');
    } else throw e;
  }
};

export const getServicesByWorkshopAction = async (
  workshopId: number
): Promise<ApiServiceInfo[]> => {
  try {
    return await workshopApi.getAvailableServicesByWorkshop({ id: workshopId });
  } catch (e) {
    if (e instanceof CouldnotRetrieveSessionError) {
      window.location.assign('/login?expired=true');
      return [];
    } else throw e;
  }
};
