import { Dispatch } from 'redux';
import {
  ApiCaseStatus,
  ApiProgressStep,
  ApiWorkshopCase,
  workshopApi,
  workshopCaseProgressApi,
} from '../../apis';
import {
  addAssistanceCase,
  addClaimCase,
  clearCases,
  clearDropDownOptions,
  setAssistanceCaseTotalCount,
  setCaseProgressStatus,
  setClaimCaseTotalCount,
  setProgressFilterOptions,
  setSelectedCase,
  setSelectedCaseProgress,
} from './caseReducer';
import { CouldnotRetrieveSessionError } from '../../common/exceptions/AuthenticationError';
import { push } from 'connected-react-router';

export const loadAssistanceCasesAction =
  (
    id: number,
    pageNumber: number,
    pageSize: number,
    licensePlate?: string,
    progressStepId?: number
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(clearCases());
    try {
      const assistanceCases = await workshopApi.getWorkshopAssistanceCases({
        id,
        caseStatus:
          progressStepId != undefined && progressStepId < 0
            ? ApiCaseStatus.Completed
            : ApiCaseStatus.Pending,
        pageNumber,
        pageSize,
        licensePlate,
        progressStepId,
      });
      dispatch(setAssistanceCaseTotalCount(assistanceCases.caseTotalCount));
      setAssistanceCases(assistanceCases.caseList, dispatch);
    } catch (e) {
      if (e instanceof CouldnotRetrieveSessionError) {
        dispatch(push('/login?expired=true'));
      } else if (e instanceof Response && e.status == 401) {
        dispatch(push('/login?expired=true'));
      } else throw e;
    }
  };

export const loadProgressStepDropdownAction =
  (caseType: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(clearDropDownOptions());
    try {
      const progressStepDropdown = await workshopApi.getProgressStepByCaseType({
        caseType,
      });
      setDropdownOptions(progressStepDropdown.progressStepList, dispatch);
    } catch (e) {
      if (e instanceof CouldnotRetrieveSessionError) {
        dispatch(push('/login?expired=true'));
      } else if (e instanceof Response && e.status == 401) {
        dispatch(push('/login?expired=true'));
      } else throw e;
    }
  };

const setAssistanceCases = (assistanceCases: ApiWorkshopCase[] | undefined, dispatch: Dispatch) => {
  if (assistanceCases && assistanceCases.length > 0) {
    assistanceCases.forEach((aCase) => dispatch(addAssistanceCase(aCase)));
  }
};

const setDropdownOptions = (
  dropdownOptions: Array<ApiProgressStep> | undefined,
  dispatch: Dispatch
) => {
  if (dropdownOptions && dropdownOptions.length > 0) {
    dispatch(setProgressFilterOptions(dropdownOptions));
  }
};

export const loadClaimCasesAction =
  (
    id: number,
    pageNumber: number,
    pageSize: number,
    licensePlate?: string,
    progressStepId?: number
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(clearCases());
    try {
      const claimCases = await workshopApi.getWorkshopClaimCases({
        id,
        caseStatus:
          progressStepId != undefined && progressStepId < 0
            ? ApiCaseStatus.Completed
            : ApiCaseStatus.Pending,
        pageNumber,
        pageSize,
        licensePlate,
        progressStepId,
      });
      dispatch(setClaimCaseTotalCount(claimCases.caseTotalCount));
      setClaimCases(claimCases.caseList, dispatch);
    } catch (e) {
      if (e instanceof CouldnotRetrieveSessionError) {
        dispatch(push('/login?expired=true'));
      } else if (e instanceof Response && e.status == 401) {
        dispatch(push('/login?expired=true'));
      } else throw e;
    }
  };

const setClaimCases = (claimCases: ApiWorkshopCase[] | undefined, dispatch: Dispatch) => {
  if (claimCases && claimCases.length > 0) {
    claimCases.forEach((aCase) => dispatch(addClaimCase(aCase)));
  }
};

export const setSelectedCaseAction =
  (selectedCase: number) =>
  (dispatch: Dispatch): void => {
    dispatch(setSelectedCase(selectedCase));
  };

export const getCaseProgressAction =
  (caseId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setSelectedCaseProgress());
    try {
      const response = await workshopCaseProgressApi.getProgressByCase({
        caseId,
      });
      dispatch(setSelectedCaseProgress(response));
    } catch (e) {
      if (e instanceof CouldnotRetrieveSessionError) {
        dispatch(push('/login?expired=true'));
      } else {
        dispatch(setSelectedCaseProgress());
        throw e;
      }
    }
  };

export const updateCaseProgressAction =
  (caseId: number, progressId: number, estimatedTime: Date | undefined) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await workshopCaseProgressApi.updateCaseProgress({
        caseId,
        progressId,
        apiCaseProgressRequest: estimatedTime
          ? {
              estimatedTime: estimatedTime,
            }
          : undefined,
      });
      dispatch(
        setCaseProgressStatus({
          progressId,
          estimatedTime,
        })
      );
    } catch (e: any) {
      if (e.status && e.status == 400)
        throw Error('Previous step is not completed or invalid estimated time');
      else if (e instanceof CouldnotRetrieveSessionError) {
        dispatch(push('/login?expired=true'));
      } else throw Error('Sorry, something went wrong.');
    }
  };
