import authentication, { AuthCredentials } from './authentication';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';

export const ACCESS_TOKEN = 'accessToken';

export const login = async (credentials: AuthCredentials): Promise<void> => {
  await authentication.signIn(credentials);
  const token = await authentication.getAccessToken();
  localStorage.setItem(ACCESS_TOKEN, token);
};

export const logoutAction =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    await authentication.signOut();
    localStorage.removeItem(ACCESS_TOKEN);
    dispatch(push('/login'));
  };
