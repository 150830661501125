import { Button, Modal } from 'react-bootstrap';
import React from 'react';
import { Translate } from 'react-redux-i18n';

export interface ModalPopupProps {
  message: string | JSX.Element;
  closeHandler: () => void;
  closeText?: string | JSX.Element | undefined;
}

export const ModalPopup: React.FC<ModalPopupProps> = ({
  message,
  closeHandler,
  closeText,
}: ModalPopupProps) => {
  return (
    <Modal show={true} onHide={closeHandler} centered style={{ zIndex: 1071 }}>
      <Modal.Header className='border-bottom-0' />
      <Modal.Body className='text-center'>
        <p style={{ whiteSpace: 'pre-line' }}>{message}</p>
      </Modal.Body>
      <Modal.Footer className='justify-content-center border-top-0'>
        <Button variant='outline-secondary' onClick={closeHandler}>
          {closeText != undefined ? closeText : <Translate value={'OK'} />}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
