import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { AppState } from '../../../store/store';
import { connect, ResolveThunks } from 'react-redux';
import './CasesDetailProgressSection.css';
import { BsCircle } from 'react-icons/bs';
import { AiFillCheckCircle } from 'react-icons/ai';
import { getCaseProgressAction, updateCaseProgressAction } from '../../cases/caseActions';
import { Loader } from '../../../components/loader/Loader';
import { ApiCaseProgress, ApiCaseStatus, ApiCaseStepEstimationType } from '../../../api';
import { DateTime, SystemZone } from 'luxon';
import { DateInput, DateTimeInput } from '../../../components/controls/DateInput';
import CaseDetailSectionTitle from '../case-detail-section/components/CaseDetailSectionTitle';
import { ModalPopup } from '../../../components/controls/ModalPopup';
import { fetchCaseDetailAction } from '../caseDetailActions';

export const CaseProgress: React.FC<CaseProgressProps> = ({
  color,
  progress,
  selectedCase,
  getCaseProgress,
  caseId,
  caseStatus,
  updateCaseProgress,
  fetchCaseDetail,
}: CaseProgressProps) => {
  const [sectionLoading, setSectionLoading] = useState(false);
  const [markCompletedLoading, setMarkCompletedLoading] = useState(false);
  useEffect(() => {
    if (selectedCase) {
      setSectionLoading(true);
      (async () => getCaseProgress(selectedCase))();
      setSectionLoading(false);
    }
  }, [selectedCase]);

  const handleComplete = async (progressStep: ApiCaseProgress, estimatedDate?: Date) => {
    setMarkCompletedLoading(true);
    try {
      const now = new Date();
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      if (
        estimatedDate &&
        (progressStep.estimationType === ApiCaseStepEstimationType.Date
          ? estimatedDate < today
          : estimatedDate < now)
      ) {
        throw Error('Estimated date cannot be in the past.');
      }
      caseId &&
        progressStep.id &&
        (await updateCaseProgress(caseId, progressStep.id, estimatedDate));
    } catch (error: any) {
      setErrorPopup(
        <ModalPopup
          message={'Error:\n' + error.message}
          closeHandler={() => setErrorPopup(<></>)}
        />
      );
    }
    if (isLastStep(progressStep) && caseId != undefined) {
      await fetchCaseDetail(caseId);
    }
    setMarkCompletedLoading(false);
  };

  const isLastStep = (progressStep: ApiCaseProgress) => {
    return !progress
      ?.map((progressStep) => progressStep.previousCaseProgressId)
      .includes(progressStep.id);
  };

  const markCompleteButton = (progressStep: ApiCaseProgress) => (
    <Button
      className='px-2 py-1 text-nowrap'
      style={{ backgroundColor: color, borderColor: color }}
      onClick={() => handleComplete(progressStep)}
    >
      {markCompletedLoading && (
        <Spinner
          animation='border'
          size='sm'
          as='span'
          role='status'
          className='mark-completed-btn-spinner'
        />
      )}
      {markCompletedLoading && ' '}
      <Translate
        value='caseDetail.caseProgress.markCompleted'
        className={'progress-section-btn-text'}
      />
    </Button>
  );

  const getPreviousAssignedDate = (index: number): Date | undefined => {
    if (!progress || index === 0) return undefined;

    const previousDatedStep = progress
      ?.slice(0, index)
      .filter(
        (apiCaseProgress) =>
          apiCaseProgress.completedTimeMs != null || apiCaseProgress.estimatedTimeMs != null
      )
      .pop();

    const previousDate = previousDatedStep?.completedTimeMs
      ? previousDatedStep.completedTimeMs
      : previousDatedStep?.estimatedTimeMs;

    return previousDate ? new Date(previousDate) : undefined;
  };

  const getNextEstimatedDate = (index: number): Date | undefined => {
    if (!progress || index + 1 === progress?.length) return undefined;

    const nextEstimatedStep = progress
      ?.slice(index + 1)
      .find((apiCaseProgress) => apiCaseProgress.estimatedTimeMs != null);

    const nextDate = nextEstimatedStep?.estimatedTimeMs
      ? new Date(nextEstimatedStep.estimatedTimeMs)
      : undefined;

    if (nextDate && nextEstimatedStep?.estimationType === ApiCaseStepEstimationType.Date) {
      nextDate.setHours(23, 59, 59, 999);
    }

    return nextDate;
  };

  const isFirstIncompleteStep = (progressStep: ApiCaseProgress) => {
    return (
      progressStep.completedTimeMs === undefined &&
      progressStep.id === progress?.find((step) => step.completedTimeMs === undefined)?.id
    );
  };

  const [errorPopup, setErrorPopup] = useState(<></>);

  return (
    <Container className='claim-section py-3 mt-1 mb-3'>
      <CaseDetailSectionTitle
        title={<Translate value='caseDetail.caseProgress.title' />}
        showDivider
      />
      {sectionLoading ? (
        <Loader />
      ) : (
        <Container className='content d-flex flex-column bg-white'>
          <div className='mt-3 text-default-color'>
            {progress &&
              progress.map((progressStep: ApiCaseProgress, index: number) => (
                <Row className='parent-step' key={index}>
                  <Col sm={6}>
                    <span className={`mr-2 center-line ${index == 0 && 'pt-0'}`}>
                      {progressStep.completedTimeMs !== undefined ? (
                        <AiFillCheckCircle size={30} className='check bg-light' />
                      ) : (
                        <BsCircle size={30} className='check bg-light icon-small' />
                      )}
                    </span>
                    {progressStep.completedTimeMs ? (
                      <Translate
                        value={`caseStatus.steps.${progressStep.nameKey}Done`}
                        className={'text-decoration-line-through'}
                      />
                    ) : (
                      <Translate value={`caseStatus.steps.${progressStep.nameKey}`} />
                    )}
                  </Col>

                  <Col sm={6} className='align-self-end d-flex'>
                    {progressStep.completedTimeMs
                      ? DateTime.fromMillis(progressStep.completedTimeMs, {
                          zone: SystemZone.instance,
                        }).toFormat('dd/MM/yyyy HH:mm')
                      : caseStatus == ApiCaseStatus.Pending &&
                        (progressStep.estimationType == ApiCaseStepEstimationType.Date ? (
                          <DateInput
                            selectedDate={
                              progressStep.estimatedTimeMs
                                ? new Date(progressStep.estimatedTimeMs)
                                : undefined
                            }
                            minDateTime={getPreviousAssignedDate(index)}
                            maxDateTime={getNextEstimatedDate(index)}
                            onChange={(date) => handleComplete(progressStep, date)}
                          />
                        ) : progressStep.estimationType == ApiCaseStepEstimationType.Timestamp ? (
                          <DateTimeInput
                            selectedDate={
                              progressStep.estimatedTimeMs
                                ? new Date(progressStep.estimatedTimeMs)
                                : undefined
                            }
                            minDateTime={getPreviousAssignedDate(index)}
                            maxDateTime={getNextEstimatedDate(index)}
                            onChange={(date) => handleComplete(progressStep, date)}
                          />
                        ) : (
                          <></>
                        ))}
                    {caseStatus == ApiCaseStatus.Pending &&
                      isFirstIncompleteStep(progressStep) &&
                      markCompleteButton(progressStep)}
                  </Col>
                </Row>
              ))}
          </div>
        </Container>
      )}
      {errorPopup}
    </Container>
  );
};

const mapStateToProps = ({ theme, cases, caseDetail }: AppState) => ({
  color: theme.color,
  selectedCase: cases.selectedCase,
  progress: cases.selectedCaseProgress?.progress,
  caseId: cases.selectedCaseProgress?.id,
  caseStatus: caseDetail.status,
});

const mapDispatchToProps = {
  getCaseProgress: getCaseProgressAction,
  updateCaseProgress: updateCaseProgressAction,
  fetchCaseDetail: fetchCaseDetailAction,
};

export type CaseProgressProps = ReturnType<typeof mapStateToProps> &
  ResolveThunks<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CaseProgress);
