import React from 'react';
import { FormInput, FormInputProps } from './FormInput';

// eslint-disable-next-line react/display-name
export const TextInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  (
    props: FormInputProps,
    ref:
      | ((instance: HTMLInputElement | null) => void)
      | React.RefObject<HTMLInputElement>
      | null
      | undefined
  ) => {
    return <FormInput {...props} type='text' ref={ref} />;
  }
);
