import React, { useState } from 'react';
import { AppState } from '../../../store/store';
import { ResolveThunks, connect } from 'react-redux';
import SwitchButton from '../../../components/controls/SwitchButton';
import { Dropdown } from 'react-bootstrap';

import './CaseSettingsDropdown.css';
import { updateCaseProgressNotificationStatusAction } from '../caseDetailActions';
import { Translate } from 'react-redux-i18n';
import { ModalPopup } from '../../../components/controls/ModalPopup';

export const CaseSettingsDropdown: React.FC<CaseSettingsDropdownProps> = ({
  color,
  isProgressNotificationsEnabled,
  caseId,
  updateCaseProgressNotificationStatus,
}: CaseSettingsDropdownProps) => {
  const [errorPopup, setErrorPopup] = useState(<></>);

  const handleProgressNotificationStatusChange = async (isChecked: boolean) => {
    try {
      caseId && (await updateCaseProgressNotificationStatus(caseId, isChecked));
    } catch (error: any) {
      setErrorPopup(
        <ModalPopup
          message={<Translate value='errors.savingProgressNotificationStatusFailed' />}
          closeHandler={() => setErrorPopup(<></>)}
        />
      );
    }
  };

  return (
    <>
      <Dropdown className='ml-sm-auto'>
        <Dropdown.Toggle
          id='case-settings-dropdown'
          data-testid='case-settings-dropdown'
          style={{ backgroundColor: color, borderColor: color }}
        >
          <span className='mr-1'>{<Translate value={'caseDetail.caseSettings.button'} />}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.ItemText style={{ display: 'flex' }} className='case-settings-dropdown-item'>
            <span className='mr-1'>
              {<Translate value={'caseDetail.caseSettings.progressNotifications.label'} />}
            </span>
            <SwitchButton
              id={'progress-notifications-switch'}
              checkedColor={'#36BB7B'}
              isSwitched={isProgressNotificationsEnabled}
              onChange={handleProgressNotificationStatusChange}
            />
          </Dropdown.ItemText>
        </Dropdown.Menu>
      </Dropdown>
      {errorPopup}
    </>
  );
};

const mapStateToProps = ({ theme, caseDetail, cases }: AppState) => ({
  color: theme.color,
  isProgressNotificationsEnabled: caseDetail.isProgressNotificationsEnabled,
  caseId: cases.selectedCaseProgress?.id,
});

const mapDispatchToProps = {
  updateCaseProgressNotificationStatus: updateCaseProgressNotificationStatusAction,
};

export type CaseSettingsDropdownProps = ReturnType<typeof mapStateToProps> &
  ResolveThunks<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CaseSettingsDropdown);
