/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiNotifier,
    ApiNotifierFromJSON,
    ApiNotifierFromJSONTyped,
    ApiNotifierToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiNotifierSection
 */
export interface ApiNotifierSection {
    /**
     * 
     * @type {ApiNotifier}
     * @memberof ApiNotifierSection
     */
    notifier?: ApiNotifier;
    /**
     * 
     * @type {ApiNotifier}
     * @memberof ApiNotifierSection
     */
    driver?: ApiNotifier;
}

export function ApiNotifierSectionFromJSON(json: any): ApiNotifierSection {
    return ApiNotifierSectionFromJSONTyped(json, false);
}

export function ApiNotifierSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiNotifierSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notifier': !exists(json, 'notifier') ? undefined : ApiNotifierFromJSON(json['notifier']),
        'driver': !exists(json, 'driver') ? undefined : ApiNotifierFromJSON(json['driver']),
    };
}

export function ApiNotifierSectionToJSON(value?: ApiNotifierSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notifier': ApiNotifierToJSON(value.notifier),
        'driver': ApiNotifierToJSON(value.driver),
    };
}

