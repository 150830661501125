import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import ThemeReducer, { ThemeState } from '../common/theme/themeReducer';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import CaseReducer, { CaseState } from '../pages/cases/caseReducer';
import HeaderReducer, { HeaderState } from '../components/header/headerReducer';
import {
  i18nReducer,
  I18nState,
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n';
import { translations } from '../locale/locale';
import CaseDetailReducer, { CaseDetailState } from '../pages/case-detail/caseDetailReducers';

export const history = createBrowserHistory();

export interface AppState {
  router: RouterState;
  theme: ThemeState;
  cases: CaseState;
  i18n: I18nState;
  header: HeaderState;
  caseDetail: CaseDetailState;
}

const persistConfig = {
  key: 'root',
  storage,
  timeout: 500,
};

const reducer = combineReducers({
  router: connectRouter(history),
  theme: ThemeReducer,
  cases: CaseReducer,
  i18n: i18nReducer,
  header: HeaderReducer,
  caseDetail: CaseDetailReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore<AppState, any>({
  // @ts-ignore
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredActionPaths: ['cases.selectedCaseProgress'],
      },
    }),
    routerMiddleware(history),
  ] as any,
});

syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale('en'));
export default store;
