/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiCaseProgress,
    ApiCaseProgressFromJSON,
    ApiCaseProgressFromJSONTyped,
    ApiCaseProgressToJSON,
    ApiVehicleDetails,
    ApiVehicleDetailsFromJSON,
    ApiVehicleDetailsFromJSONTyped,
    ApiVehicleDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiWorkshopCase
 */
export interface ApiWorkshopCase {
    /**
     * 
     * @type {number}
     * @memberof ApiWorkshopCase
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ApiWorkshopCase
     */
    registrationTimeMs?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiWorkshopCase
     */
    accidentTimeMs?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiWorkshopCase
     */
    serviceName: string;
    /**
     * 
     * @type {ApiCaseProgress}
     * @memberof ApiWorkshopCase
     */
    progress?: ApiCaseProgress;
    /**
     * 
     * @type {ApiVehicleDetails}
     * @memberof ApiWorkshopCase
     */
    vehicle: ApiVehicleDetails;
}

export function ApiWorkshopCaseFromJSON(json: any): ApiWorkshopCase {
    return ApiWorkshopCaseFromJSONTyped(json, false);
}

export function ApiWorkshopCaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiWorkshopCase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'registrationTimeMs': !exists(json, 'registrationTimeMs') ? undefined : json['registrationTimeMs'],
        'accidentTimeMs': !exists(json, 'accidentTimeMs') ? undefined : json['accidentTimeMs'],
        'serviceName': json['serviceName'],
        'progress': !exists(json, 'progress') ? undefined : ApiCaseProgressFromJSON(json['progress']),
        'vehicle': ApiVehicleDetailsFromJSON(json['vehicle']),
    };
}

export function ApiWorkshopCaseToJSON(value?: ApiWorkshopCase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'registrationTimeMs': value.registrationTimeMs,
        'accidentTimeMs': value.accidentTimeMs,
        'serviceName': value.serviceName,
        'progress': ApiCaseProgressToJSON(value.progress),
        'vehicle': ApiVehicleDetailsToJSON(value.vehicle),
    };
}

