import React from 'react';
import './CustomBadge.css';

interface CustomBadgeProps {
  text: JSX.Element;
  textColor: string;
  backgroundColor: string;
}
export const CustomBadge: React.FC<CustomBadgeProps> = ({
  text,
  textColor,
  backgroundColor,
}: CustomBadgeProps) => {
  return (
    <span
      className='custom-badge ml-1'
      style={{ backgroundColor: backgroundColor, color: textColor }}
    >
      {text}
    </span>
  );
};
