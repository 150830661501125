/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiWorkshopCase,
    ApiWorkshopCaseFromJSON,
    ApiWorkshopCaseFromJSONTyped,
    ApiWorkshopCaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiWorkshopCasePage
 */
export interface ApiWorkshopCasePage {
    /**
     * 
     * @type {number}
     * @memberof ApiWorkshopCasePage
     */
    caseTotalCount: number;
    /**
     * 
     * @type {Array<ApiWorkshopCase>}
     * @memberof ApiWorkshopCasePage
     */
    caseList?: Array<ApiWorkshopCase>;
}

export function ApiWorkshopCasePageFromJSON(json: any): ApiWorkshopCasePage {
    return ApiWorkshopCasePageFromJSONTyped(json, false);
}

export function ApiWorkshopCasePageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiWorkshopCasePage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'caseTotalCount': json['caseTotalCount'],
        'caseList': !exists(json, 'caseList') ? undefined : ((json['caseList'] as Array<any>).map(ApiWorkshopCaseFromJSON)),
    };
}

export function ApiWorkshopCasePageToJSON(value?: ApiWorkshopCasePage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'caseTotalCount': value.caseTotalCount,
        'caseList': value.caseList === undefined ? undefined : ((value.caseList as Array<any>).map(ApiWorkshopCaseToJSON)),
    };
}

