import React from 'react';
import { Dropdown } from 'react-bootstrap';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import { Translate } from 'react-redux-i18n';

export enum SelectedSmsLinkType {
  CLAIM = 'Claim',
  ASSISTANCE = 'Assistance',
}

export interface SmsLinkSelectorProps {
  color: string;
  onSelect: (linkType: SelectedSmsLinkType) => void;
}

const SmsLinkSelector: React.FC<SmsLinkSelectorProps> = ({
  color,
  onSelect,
}: SmsLinkSelectorProps) => {
  const handleSmsLinkTypeSelection = async (eventKey: string | null) => {
    eventKey === SelectedSmsLinkType.CLAIM
      ? onSelect(SelectedSmsLinkType.CLAIM)
      : onSelect(SelectedSmsLinkType.ASSISTANCE);
  };

  return (
    <>
      <Dropdown onSelect={handleSmsLinkTypeSelection} className='ml-sm-auto'>
        <Dropdown.Toggle
          data-testid='sms-link-type-selector'
          style={{ backgroundColor: color, borderColor: color }}
        >
          <Translate value='smsLinkSelector.smsLink' />
        </Dropdown.Toggle>
        <DropdownMenu alignRight>
          <Dropdown.Item eventKey={SelectedSmsLinkType.CLAIM}>
            <Translate value='smsLinkSelector.claimLink' />
          </Dropdown.Item>
          <Dropdown.Item eventKey={SelectedSmsLinkType.ASSISTANCE}>
            <Translate value='smsLinkSelector.assistanceLink' />
          </Dropdown.Item>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default SmsLinkSelector;
