import * as yup from 'yup';

export const REQUIRED_FIELD = 'Required field';
export const INVALID_EMAIL = 'Must be a valid email';

const passwordLength = 4;
export const emailFormat =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const passwordValidation = yup
  .string()
  .required(REQUIRED_FIELD)
  .test('len', REQUIRED_FIELD, (v) => v !== undefined && v.trim().length > passwordLength);

export const emailValidation = yup
  .string()
  .required(REQUIRED_FIELD)
  .test('len', REQUIRED_FIELD, (v) => v !== undefined && v.trim().length > 0)
  .matches(emailFormat, INVALID_EMAIL);

export const notEmptyValidation = yup
  .string()
  .required(REQUIRED_FIELD)
  .test('len', REQUIRED_FIELD, (v) => v !== undefined && v.trim().length > passwordLength);
