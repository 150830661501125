/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiCaseProgressRequest
 */
export interface ApiCaseProgressRequest {
    /**
     * if estimationType is DATE - time part must be truncated to start of the day
     * @type {Date}
     * @memberof ApiCaseProgressRequest
     */
    estimatedTime: Date;
}

export function ApiCaseProgressRequestFromJSON(json: any): ApiCaseProgressRequest {
    return ApiCaseProgressRequestFromJSONTyped(json, false);
}

export function ApiCaseProgressRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCaseProgressRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'estimatedTime': (new Date(json['estimatedTime'])),
    };
}

export function ApiCaseProgressRequestToJSON(value?: ApiCaseProgressRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'estimatedTime': (value.estimatedTime.toISOString()),
    };
}

