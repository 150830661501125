/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiCaseProgressNotificationStatus
 */
export interface ApiCaseProgressNotificationStatus {
    /**
     * 
     * @type {boolean}
     * @memberof ApiCaseProgressNotificationStatus
     */
    isProgressNotificationEnabled: boolean;
}

export function ApiCaseProgressNotificationStatusFromJSON(json: any): ApiCaseProgressNotificationStatus {
    return ApiCaseProgressNotificationStatusFromJSONTyped(json, false);
}

export function ApiCaseProgressNotificationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCaseProgressNotificationStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isProgressNotificationEnabled': json['isProgressNotificationEnabled'],
    };
}

export function ApiCaseProgressNotificationStatusToJSON(value?: ApiCaseProgressNotificationStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isProgressNotificationEnabled': value.isProgressNotificationEnabled,
    };
}

