import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TextInput } from '../../components/controls/TextInput';
import { Translate } from 'react-redux-i18n';
import { ActionButton } from '../../components/controls/ActionButton';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/cjs/Container';
import { Form } from 'react-bootstrap';
import { SelectDropDown } from '../../components/controls/SelectDropDown';
import {
  getServicesByWorkshopAction,
  sendClaimSmsLinkAction,
  sendAssistanceSmsLinkAction,
} from './sendSmsLinkModalActions';
import foldersIcon from '../../assets/folders.svg';
import { SectionHeader } from '../../components/sectionHeader/SectionHeader';
import './SendSmsLinkModal.css';
import { ApiServiceInfo } from '../../api/models';
import { PhoneNumberInput } from '../../components/controls/PhoneNumberInput';
import { countryCodes, countryListForCodes } from './phoneNumberConsants';

export interface Props {
  workshopId: number;
  isClaimSmsLink: boolean;
  show: boolean;
  onClose: () => void;
}

export const SendSmsLinkModal: React.FC<Props> = ({
  workshopId,
  isClaimSmsLink,
  show,
  onClose,
}: Props) => {
  const methods = useForm({
    mode: 'onTouched',
  });

  const [services, setServices] = useState(new Array<ApiServiceInfo>());
  useEffect(() => {
    (async () => {
      const services = await getServicesByWorkshopAction(workshopId);
      setServices(services);
    })();
  }, []);

  const { handleSubmit, errors, register } = methods;
  const [isSubmitting, setSubmitting] = useState(false);
  const [countryCode, setCountryCode] = useState('EE');

  const handleCountryCodeChange = (code: string) => {
    setCountryCode(code);
  };

  const onSubmit = handleSubmit(
    async (formValues: {
      phoneNumber: string;
      licensePlate: string;
      serviceExternalId: string;
    }) => {
      setSubmissionErrorText('');
      setSubmitting(true);
      try {
        if (isClaimSmsLink) {
          await sendClaimSmsLinkAction(
            workshopId,
            countryCodes[countryCode],
            formValues.phoneNumber,
            formValues.licensePlate,
            Number(formValues.serviceExternalId)
          );
        } else {
          await sendAssistanceSmsLinkAction(
            workshopId,
            countryCodes[countryCode],
            formValues.phoneNumber,
            formValues.licensePlate,
            Number(formValues.serviceExternalId)
          );
        }
        setShowModal(false);
        setShowSuccessModal(true);
      } catch (ignore) {
        setSubmissionErrorText('Sorry, something went wrong.');
      } finally {
        setSubmitting(false);
      }
    }
  );

  const [showModal, setShowModal] = useState(show);
  const closeHandler = () => {
    setShowModal(false);
    onClose();
  };

  const [submissionErrorText, setSubmissionErrorText] = useState('');
  const submissionError = submissionErrorText && (
    <p className='text-danger'>{submissionErrorText}</p>
  );

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const onCloseSuccessModal = () => {
    onClose();
    setShowSuccessModal(false);
  };

  const successModal = (
    <Modal show={showSuccessModal} onHide={onCloseSuccessModal} centered data-testid='smsLinkModal'>
      <Modal.Header className='border-bottom-0' closeButton />
      <Modal.Body>
        <Container className='w-75 text-center'>
          <img src={foldersIcon} alt='' style={{ width: '48px', height: '48px' }} />
          <SectionHeader
            text={
              <Translate
                value={
                  isClaimSmsLink
                    ? 'sendSmsLinkModal.claimLinkSentSuccessfully'
                    : 'sendSmsLinkModal.assistanceLinkSentSuccessfully'
                }
              />
            }
            cssClass='modal-txt hdr'
          />
          <p className='modal-txt scndry'>
            <Translate value='sendSmsLinkModal.ifLinkWasntSentMessage' />
          </p>
        </Container>
      </Modal.Body>
      <Modal.Footer className='justify-content-center border-top-0'>
        <ActionButton
          variant={'success'}
          text={<Translate value={'sendSmsLinkModal.continue'} />}
          onClick={onCloseSuccessModal}
        />
      </Modal.Footer>
    </Modal>
  );

  return (
    <>
      <Modal show={showModal} onHide={closeHandler} centered>
        <FormProvider {...methods}>
          <Form onSubmit={onSubmit}>
            <Modal.Header className='border-bottom-0' closeButton />
            <Modal.Body className=''>
              <Container className='w-76'>
                <SectionHeader
                  text={
                    <Translate
                      value={
                        isClaimSmsLink
                          ? 'sendSmsLinkModal.claimLinkModalTitle'
                          : 'sendSmsLinkModal.assistanceLinkModalTitle'
                      }
                    />
                  }
                  cssClass='modal-txt hdr'
                />
                <TextInput
                  name='licensePlate'
                  label={<Translate value={'sendSmsLinkModal.licensePlateLabel'} />}
                  controlId='licensePlate'
                  isInvalid={!!errors.licensePlate}
                  ref={register({ required: true })}
                  validationError={<Translate value={'common.required'} />}
                  cssClass='text-uppercase'
                />
                <PhoneNumberInput
                  name='phoneNumber'
                  label={<Translate value={'sendSmsLinkModal.phoneNumberLabel'} />}
                  controlId='phoneNumber'
                  ref={register({ required: true })}
                  isInvalid={!!errors.phoneNumber}
                  validationError={<Translate value={'common.required'} />}
                  testId={'phoneNumber'}
                  countryCode={countryCode}
                  countryCodes={countryCodes}
                  countryListForCodes={countryListForCodes}
                  onCountryCodeChange={(code: string) => handleCountryCodeChange(code)}
                />
                <SelectDropDown
                  name='serviceExternalId'
                  excludeEmptyOption={services.length == 1}
                  ref={register({ required: true })}
                  label={<Translate value={'sendSmsLinkModal.serviceSelectorLabel'} />}
                  entries={services}
                  isInvalid={!!errors.serviceExternalId}
                  validationError={<Translate value={'common.required'} />}
                  testId='serviceSelector'
                />
                {submissionError}
              </Container>
            </Modal.Body>
            <Modal.Footer className='justify-content-center border-top-0'>
              <ActionButton
                variant={'success'}
                type='submit'
                loading={isSubmitting}
                text={<Translate value={'sendSmsLinkModal.sendSms'} />}
              />
            </Modal.Footer>
          </Form>
        </FormProvider>
      </Modal>
      {successModal}
    </>
  );
};
