import React from 'react';
import { Container } from 'react-bootstrap';
import './CaseDetailSectionField.css';

export interface CaseDetailSectionFieldProps {
  title: JSX.Element;
  body: string | JSX.Element | undefined;
  bodyTestId?: string;
}
const CaseDetailSectionField: React.FC<CaseDetailSectionFieldProps> = ({
  title,
  body,
  bodyTestId,
}: CaseDetailSectionFieldProps) => {
  return (
    <Container className={'d-flex mx-0 px-0 mb-2'}>
      <div className={'section-field-title'}>{title}</div>
      <div data-testid={bodyTestId} className={'section-field-body'}>
        {body}
      </div>
    </Container>
  );
};

export default CaseDetailSectionField;
