import { LanguageType } from './LanguageType';

export const et: LanguageType = {
  errors: {
    tryAgain: 'Vabandust, midagi läks valesti. Proovige hiljem uuesti.',
    sessionExpiredLoginAgain: 'Sessioon aegus, logi palun uuesti sisse.',
    savingCommentFailed: 'Ei õnnestunud kommentaari salvestada. Palun proovige uuesti.',
    savingProgressNotificationStatusFailed:
      'Ebaõnnestus juhtumi teadete saatmise salvestamine. Palun proovige hiljem uuesti.',
  },
  login: {
    email: 'E-post',
    password: 'Parool',
    login: 'Sisene',
    errors: {
      email: 'Vigane e-post',
      password: 'Vigane parool',
    },
  },
  caseList: {
    tab: {
      claims: 'Kindlustusjuhtumid',
      assistanceRequests: 'Autoabi',
    },
    licensePlate: 'Sõiduki number',
    vehicle: 'Sõiduk',
    type: 'Tüüp',
    registrationDate: 'Reg kuupäev',
    accidentDate: 'Juhtumi kuupäev',
    serviceName: 'Teenus',
    progress: 'Staatus',
    noAssistanceCasesFound: 'Ühtegi autoabi juhtumit ei leitud',
    noClaimCasesFound: 'Ühtegi kindlustusjuhtumit ei leitud',
    toBeEstimated: ' (aeg määramata)',
  },
  caseDetail: {
    caseType: {
      CLAIM_INSURANCE: 'Kindlustusjuhtumid',
      ROADSIDE_ASSISTANCE: 'Autoabi',
    },
    caseStatus: {
      PENDING: 'TÖÖS',
      CANCELED: 'TÜHISTATUD',
      COMPLETED: 'LÕPETATUD',
    },
    cancelCase: {
      button: 'Tühista Juhtum',
      modalTitle: 'Tühista juhtum',
      modalBody: 'Oled kindel, et soovid juhtumi tühistada?',
      yes: 'JAH',
      no: 'EI',
    },
    caseDetailSection: {
      title: 'JUHTUMI ÜKSIKASJAD',
      type: 'TÜÜP',
      registrationDate: 'REG KUUPÄEV',
      serviceName: 'TEENUS',
    },
    vehicleInfoSection: {
      title: 'SÕIDUKI ANDMED',
      licensePlate: 'Sõiduki number',
      mark: 'MARK',
      model: 'MUDEL',
      year: 'AASTA',
    },
    notifierInfoSection: {
      title: 'TEAVITAJA/JUHI ANDMED',
      name: 'NIMI',
      phone: 'TELEFON',
      email: 'E-POST',
      personalCode: 'ISIKUKOOD',
      driverName: 'JUHI NIMI',
      driverPhone: 'JUHI TELEFON',
      driverEmail: 'JUHI E-POST',
      driverPersonalCode: 'JUHI ISIKUKOOD',
    },
    communicationPreference: {
      label: 'EELISTATUD SUHTLUSKANAL',
      email: 'E-POST',
      sms: 'SMS',
      emailAndSms: 'E-POST & SMS',
    },
    accidentDetailsSection: {
      title: 'ÕNNETUSE ÜKSIKASJAD',
      incidentType: 'JUHTUMI TÜÜP',
      date: 'KUUPÄEV & KELLAAEG',
      location: 'ASUKOHT',
      description: 'KIRJELDUS',
    },
    otherPartiesSection: {
      title: 'TEISED OSAPOOLED',
      description: 'KIRJELDUS',
      name: 'NIMI',
      personalCode: 'ISIKUKOOD',
      phone: 'TELEFON',
      email: 'E-POST',
    },
    infoTab: {
      claims: 'Kindlustusjuhtumid',
      assistance: 'Autoabi',
    },
    documentsSection: {
      title: 'DOKUMENDID',
    },
    photos: 'PILDID',
    downloadAll: 'Laadi kõik alla',
    caseProgress: {
      title: 'JUHTUMI EDENEMINE',
      markCompleted: 'Märgi lõpetatuks',
      change: 'Muuda',
    },
    noData: 'Andmed puuduvad',
    commentsSection: {
      title: 'KOMMENTAARID',
      save: 'Salvesta',
      commentBoxPlaceholder: 'Sisestage oma kommentaar ja klõpsake salvestada',
    },
    caseSettings: {
      button: 'Seaded',
      progressNotifications: {
        label: 'Teavituste saatmine',
      },
    },
  },
  sendSmsLinkModal: {
    claimLinkModalTitle: 'Saada registreerimise SMS',
    assistanceLinkModalTitle: 'Saada abitaotluse link SMSi teel',
    licensePlateLabel: 'Sisesta sõiduki number',
    phoneNumberLabel: 'Sisesta telefon',
    serviceSelectorLabel: 'Vali teenus',
    sendSms: 'SAADA SMS',
    claimLinkSentSuccessfully: 'Taotluse link edukalt saadetud',
    assistanceLinkSentSuccessfully: 'Abi link edukalt saadetud',
    ifLinkWasntSentMessage: 'Kui mingil põhjusel link ei saabunud, proovige uuesti.',
    continue: 'JÄTKA',
  },
  header: {
    searchText: 'Otsi sõiduki numbri järgi',
  },
  common: {
    required: 'Kohustuslik väli!',
  },
  caseStatus: {
    title: 'Juhtumi staatus',
    registration: 'Sõiduki number',
    policyInfo: 'Poliisi info',
    steps: {
      caseRegistered: 'Juhtum registreeritud',
      caseRegisteredDone: 'Juhtum registreeritud',
      vehicleDeliveredToWorkshop: 'Sõiduk toimetatud töökotta',
      vehicleDeliveredToWorkshopDone: 'Sõiduk toimetatud töökotta',
      estimatedDiagnostics: 'Diagnostika',
      estimatedDiagnosticsDone: 'Diagnostika lõpetatud',
      preparingRepairEstimate: 'Remondikulude kalkulatsioon ettevalmistamisel',
      preparingRepairEstimateDone: 'Remondikulude kalkulatsioon lõpetatud',
      confirmingRepairEstimate: 'Remondikulude kalkulatsioon kinnitamisel',
      confirmingRepairEstimateDone: 'Remondikulude kalkulatsioon kinnitatud',
      estimatedArrivalOfSpareParts: 'Varuosade saabumine',
      estimatedArrivalOfSparePartsDone: 'Varuosad saabunud',
      repairingStarts: 'Tööde algus',
      repairingStartsDone: 'Töödega alustatud',
      estimatedEndOfRepair: 'Tööde lõpp',
      estimatedEndOfRepairDone: 'Tööde lõpp',
      pickUpVehicle: 'Teie sõiduk on valmis',
      pickUpVehicleDone: 'Sõiduk üle antud',
    },
    nextStep: 'Teie juhtum on käsitluses. Järgmine samm on',
    errors: {
      invalidOrExpiredLink: 'Link on aegunud või kehtetu',
    },
  },
  incidentType: {
    WINDSHIELD_DAMAGE: 'Klaasikahju',
    TRAFFIC_ACCIDENT: 'Liiklusõnnetus',
    HIT_WILD_ANIMAL: 'Kokkupõrge loomaga',
    TECHNICAL_BREAKDOWN: 'Tehniline rike',
    THEFT: 'Vargus',
    VANDALISM_OR_FIRE: 'Vandalism või tulekahju',
    OTHER: 'Muu',
    EMPTY_BATTERY: 'Aku tühi',
    RUN_OUT_OF_FUEL: 'Kütus otsas',
    FLAT_TIRES: 'Rehv tühi',
    STUCK_IN_SAND_SNOW_MUD: 'Kinni liiv/lumi/muda',
    DOOR_LOCKED: 'Uksed lukus',
    UNKNOWN_TECHNICAL_ISSUE: 'Teadmata tehniline rike',
  },
  pagination: {
    rowsPerPage: 'Ridu lehel',
  },
  logout: 'Logi välja',
  filters: {
    search: 'Otsing',
    filterByProgressStep: 'Filtreeri staatuse järgi',
  },
  addEmployee: {
    name: 'Täisnimi',
    phone: 'Telefoninumber',
    email: 'E-post',
    password: 'Parool',
    header: 'Lisa töötaja',
    workshop: 'Töökoda',
    saveEmployee: 'Salvesta',
    success:
      'Töötaja lisamine õnnestus ja talle saadeti kinnitus. Pärast kinnitamist saab töötaja sisse logida.',
    errors: {
      email: 'E-post pole korrektne',
      phone: 'Telefoninumber pole korrektne',
      password: 'Vale parool',
      name: 'Nimi pole korrektne',
      alreadyAssigned: 'Määratud kasutaja juba eksisteerib selles töökojas.',
    },
  },
  refreshPageToApplyChangesPopup: {
    refresh: 'Värskenda',
    refreshPageToSeeUpdates:
      'Oleme lehekülge uuendanud. Värskendage lehte, et näha viimaseid muudatusi.',
  },
  smsLinkSelector: {
    smsLink: 'SMS LINK',
    claimLink: 'Juhtumi',
    assistanceLink: 'Autoabi',
  },
};
