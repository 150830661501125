/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiAccidentDetailsSection
 */
export interface ApiAccidentDetailsSection {
    /**
     * 
     * @type {string}
     * @memberof ApiAccidentDetailsSection
     */
    incidentType?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApiAccidentDetailsSection
     */
    dateAndTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApiAccidentDetailsSection
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAccidentDetailsSection
     */
    description?: string;
}

export function ApiAccidentDetailsSectionFromJSON(json: any): ApiAccidentDetailsSection {
    return ApiAccidentDetailsSectionFromJSONTyped(json, false);
}

export function ApiAccidentDetailsSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAccidentDetailsSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incidentType': !exists(json, 'incidentType') ? undefined : json['incidentType'],
        'dateAndTime': !exists(json, 'dateAndTime') ? undefined : (new Date(json['dateAndTime'])),
        'location': !exists(json, 'location') ? undefined : json['location'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function ApiAccidentDetailsSectionToJSON(value?: ApiAccidentDetailsSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incidentType': value.incidentType,
        'dateAndTime': value.dateAndTime === undefined ? undefined : (value.dateAndTime.toISOString()),
        'location': value.location,
        'description': value.description,
    };
}

