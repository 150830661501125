import React, { useState } from 'react';
import store from './store/store';
import { Router } from './routing/routes';
import { Provider } from 'react-redux';
import { configureAmplify } from './configs/amplify';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { useEffect } from 'react';
import { refreshTranslations } from './locale/languageActions';
import { useServiceWorker } from './service-worker/useServiceWorker';
import { ModalPopup } from './components/controls/ModalPopup';
import { Translate } from 'react-redux-i18n';

const App: React.FC = () => {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const [refreshPagePopup, setRefreshPagePopup] = useState(<></>);

  configureAmplify();

  useEffect(() => {
    store.dispatch(refreshTranslations());
  }, []);

  useEffect(() => {
    if (showReload && waitingWorker) {
      setRefreshPagePopup(
        <ModalPopup
          message={<Translate value={'refreshPageToApplyChangesPopup.refreshPageToSeeUpdates'} />}
          closeText={<Translate value={'refreshPageToApplyChangesPopup.refresh'} />}
          closeHandler={() => {
            setRefreshPagePopup(<></>);
            reloadPage();
          }}
        />
      );
    }
  }, [waitingWorker, showReload, reloadPage]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistStore(store)}>
        <Router />
        {refreshPagePopup}
      </PersistGate>
    </Provider>
  );
};

export default App;
