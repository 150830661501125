import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiWorkshopEmployee } from '../../api';

export interface HeaderState {
  searchPhrase: string;
  employee: ApiWorkshopEmployee | undefined;
  currentWorkshop: number | undefined;
}

const initialState: HeaderState = {
  searchPhrase: '',
  employee: undefined,
  currentWorkshop: undefined,
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setSearchPhrase: (state: HeaderState, action: PayloadAction<string>) => {
      state.searchPhrase = action.payload;
    },
    clearSearchPhrase: (state: HeaderState) => {
      state.searchPhrase = initialState.searchPhrase;
    },
    setEmployee: (state: HeaderState, action: PayloadAction<ApiWorkshopEmployee | undefined>) => {
      state.employee = action.payload;
    },
    setCurrentWorkshop: (state: HeaderState, action: PayloadAction<number | undefined>) => {
      state.currentWorkshop = action.payload;
    },
  },
});

const { actions, reducer } = headerSlice;

export const { setSearchPhrase, clearSearchPhrase, setEmployee, setCurrentWorkshop } = actions;

export default reducer;
