import { LanguageType } from './LanguageType';

export const lv: LanguageType = {
  errors: {
    tryAgain: 'Atvainojiet, kaut kas notika nepareizi. Mēģiniet vēlreiz vēlāk.',
    sessionExpiredLoginAgain: 'Session expired, please log in again.',
    savingCommentFailed: 'Komentāru neizdevās saglabāt. Lūdzu, mēģiniet vēlreiz.',
    savingProgressNotificationStatusFailed:
      'Neizdevās saglabāt progresa paziņojuma statusu. Lūdzu, mēģiniet vēlreiz vēlāk.',
  },
  login: {
    email: 'E-pasts',
    password: 'Parole',
    login: 'Pieslēgties',
    errors: {
      email: 'Nederīgs e-pasts',
      password: 'Nederīga parole',
    },
  },
  caseList: {
    tab: {
      claims: 'Prasības',
      assistanceRequests: 'Palīdzības pieprasījumi',
    },
    licensePlate: 'Transporta līdzekļa numuru',
    vehicle: 'Transportlīdzeklis',
    type: 'Tips',
    registrationDate: 'Reģistrācijas datums',
    accidentDate: 'Accident date',
    serviceName: 'Pakalpojums',
    progress: 'Progresu',
    noAssistanceCasesFound: 'Palīdzības gadījumi nav atrasti',
    noClaimCasesFound: 'Nav atrasti prasījumu gadījumi',
    toBeEstimated: ' (jānovērtē)',
  },
  caseDetail: {
    caseType: {
      CLAIM_INSURANCE: 'Prasības',
      ROADSIDE_ASSISTANCE: 'Palīdzības pieprasījumi',
    },
    caseStatus: {
      PENDING: 'PENDING',
      CANCELED: 'CANCELED',
      COMPLETED: 'COMPLETED',
    },
    cancelCase: {
      button: 'Cancel Case',
      modalTitle: 'Cancel Case',
      modalBody: 'Are you sure you want to cancel this case?',
      yes: 'JĀ',
      no: 'NĒ',
    },
    caseDetailSection: {
      title: 'LIETAS DETAĻAS',
      type: 'TIPS',
      registrationDate: 'REĢISTRĀCIJAS DATUMS',
      serviceName: 'PAKALPOJUMS',
    },
    vehicleInfoSection: {
      title: 'INFORMĀCIJA PAR TRANSPORTLĪDZEKLI',
      licensePlate: 'TRANSPORTA LĪDZEKĻA NUMURU',
      mark: 'MARKA',
      model: 'MODELIS',
      year: 'GADS',
    },
    notifierInfoSection: {
      title: 'PAZIŅOTĀJA/VADĪTĀJA INFORMĀCIJA',
      name: 'VĀRDS',
      phone: 'TELEFONA NUMURS',
      email: 'E-PASTS',
      personalCode: 'PERSONAS KODS',
      driverName: 'AUTOVADĪTĀJS VĀRDS',
      driverPhone: 'AUTOVADĪTĀJS TELEFONA NUMURS',
      driverEmail: 'AUTOVADĪTĀJS E-PASTS',
      driverPersonalCode: 'AUTOVADĪTĀJS PERSONAS KODS',
    },
    communicationPreference: {
      label: 'PRIEKŠROKA SAZIŅAI',
      email: 'E-PASTS',
      sms: 'SMS',
      emailAndSms: 'E-PASTS & SMS',
    },
    accidentDetailsSection: {
      title: 'INFORMĀCIJA PAR NEGADĪJUMU',
      incidentType: 'NEGADĪJUMA VEIDS',
      date: 'DATUMS & LAIKS',
      location: 'ATRAŠANĀS VIETAS',
      description: 'APRAKSTS',
    },
    otherPartiesSection: {
      title: 'CITAS PUSES',
      description: 'APRAKSTS',
      name: 'VĀRDS',
      personalCode: 'PERSONAS KODS',
      phone: 'TELEFONA NUMURS',
      email: 'E-PASTS',
    },
    infoTab: {
      claims: 'Prasības',
      assistance: 'Palīdzības pieprasījumi',
    },
    documentsSection: {
      title: 'DOKUMENTI',
    },
    photos: 'FOTOGRĀFIJAS',
    downloadAll: 'Lejupielādēt visu',
    caseProgress: {
      title: 'LIETAS VIRZĪBA',
      markCompleted: 'Atzīme Pabeigts',
      change: 'Mainīt datumu',
    },
    noData: 'Nav datu',
    commentsSection: {
      title: 'KOMENTĀRI',
      save: 'Saglabāt',
      commentBoxPlaceholder: 'Ierakstiet savu komentāru un noklikšķiniet uz Saglabāt',
    },
    caseSettings: {
      button: 'Iestatījumi',
      progressNotifications: {
        label: 'Nosūtīt progresa paziņojumus',
      },
    },
  },
  sendSmsLinkModal: {
    claimLinkModalTitle: 'Nosūtīt prasījuma saiti, izmantojot SMS',
    assistanceLinkModalTitle: 'Palīdzības pieprasījuma saites nosūtīšana, izmantojot SMS',
    licensePlateLabel: 'Ievadiet transporta līdzekļa numuru',
    phoneNumberLabel: 'Ievadiet telefona numurs',
    serviceSelectorLabel: 'Izvēlieties pakalpojumu',
    sendSms: 'SŪTĪT SMS',
    claimLinkSentSuccessfully: 'Prasības saite veiksmīgi nosūtīta',
    assistanceLinkSentSuccessfully: 'Palīdzības saite veiksmīgi nosūtīta',
    ifLinkWasntSentMessage:
      'Ja kāda iemesla dēļ neesat saņēmis saiti, mēģiniet prasību nosūtīt atkārtoti.',
    continue: 'TURPINĀT',
  },
  header: {
    searchText: 'Meklēt pēc numura zīmes',
  },
  common: {
    required: 'Obligātais lauks!',
  },
  caseStatus: {
    title: 'Lietas statuss',
    registration: 'Reģistrācijas Nr.',
    policyInfo: 'Informācija par politiku',
    steps: {
      caseRegistered: 'Reģistrēts gadījums',
      caseRegisteredDone: 'Reģistrēts gadījums',
      vehicleDeliveredToWorkshop: 'Uz darbnīcu piegādātais transportlīdzeklis',
      vehicleDeliveredToWorkshopDone: 'Uz darbnīcu piegādātais transportlīdzeklis',
      estimatedDiagnostics: 'Aprēķinātā diagnostika',
      estimatedDiagnosticsDone: 'Veikta diagnostika',
      preparingRepairEstimate: 'Remonta tāmes sagatavošana',
      preparingRepairEstimateDone: 'Pabeigta remonta tāme',
      confirmingRepairEstimate: 'Remonta tāmes apstiprināšana',
      confirmingRepairEstimateDone: 'Apstiprināta remonta tāme',
      estimatedArrivalOfSpareParts: 'Paredzamā detaļu piegāde',
      estimatedArrivalOfSparePartsDone: 'Rezerves daļas ieradās',
      repairingStarts: 'Remonta uzsākšana',
      repairingStartsDone: 'Uzsākts remonts',
      estimatedEndOfRepair: 'Paredzamais remonta beigas',
      estimatedEndOfRepairDone: 'Remonts beidzās',
      pickUpVehicle: 'Lūdzu, saņemt transportlīdzekli',
      pickUpVehicleDone: 'Transportlīdzekļa paņemšana',
    },
    nextStep: 'Jūsu lieta tiek izskatīta. Nākamais solis ir',
    errors: {
      invalidOrExpiredLink: 'Saites derīguma termiņš ir beidzies vai tā ir nederīga',
    },
  },
  incidentType: {
    WINDSHIELD_DAMAGE: 'Vējstikla bojājums',
    TRAFFIC_ACCIDENT: 'Satiksmes negadījums',
    HIT_WILD_ANIMAL: 'Sadursme ar meža dzīvnieku',
    TECHNICAL_BREAKDOWN: 'Tehnisks bojājums',
    THEFT: 'Zādzība',
    VANDALISM_OR_FIRE: 'Vandālisms vai degšana',
    OTHER: 'Cits',
    EMPTY_BATTERY: 'Tukšs/izlādējies akumulators',
    RUN_OUT_OF_FUEL: 'Izbeidzās degviela',
    FLAT_TIRES: 'Tukša riepa',
    STUCK_IN_SAND_SNOW_MUD: 'Iestrēdzis smiltīs/sniegā/dubļos',
    DOOR_LOCKED: 'Aizbloķējušās durvis',
    UNKNOWN_TECHNICAL_ISSUE: 'Nezināmas izcelsmes tehniska problēma',
  },
  pagination: {
    rowsPerPage: 'Rindas vienā lappusē',
  },
  logout: 'Pieteikšanās',
  filters: {
    search: 'Meklēt',
    filterByProgressStep: 'Filtrējiet pēc progresa soļa',
  },
  addEmployee: {
    name: 'Pilnas vardas',
    phone: 'Telefono numeris',
    email: 'El. paštas',
    password: 'Slaptažodis',
    header: 'Pridėti darbuotoją',
    workshop: 'Seminaras',
    saveEmployee: 'Sutaupyti',
    success:
      'Darbuotojas sėkmingai pridėtas ir jam/jai išsiųstas patvirtinimo el. Patvirtinus darbuotojas galės prisijungti.',
    errors: {
      email: 'Neteisingas el. pašto adresas',
      phone: 'Neteisingas telefono numeris',
      password: 'Neteisingas slaptažodis',
      name: 'Neteisingas vardas',
      alreadyAssigned: 'Nurodytas vartotojas jau priskirtas šiam seminarui',
    },
  },
  refreshPageToApplyChangesPopup: {
    refresh: 'Refresh',
    refreshPageToSeeUpdates:
      'Mēs esam atjauninājuši lapu. Lūdzu, atsvaidziniet lapu, lai redzētu jaunākās izmaiņas.',
  },
  smsLinkSelector: {
    smsLink: 'SMS SAITE',
    claimLink: 'Pieprasījuma iesniegšana',
    assistanceLink: 'Palīdzība uz ceļa',
  },
};
