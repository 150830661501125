import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import authentication from '../auth/authentication';
import { push } from 'connected-react-router';
import { ACCESS_TOKEN } from '../auth/authenticationActions';

const mapDispatchToProps = {
  redirectToLogin: () => push('/login'),
  redirectToHomePage: () => push('/'),
};
const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export type PrivateRouteProps = RouteProps & PropsFromRedux & { roles?: string[] };

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  redirectToLogin,
  redirectToHomePage,
  roles,
  ...rest
}: PrivateRouteProps) => {
  async function checkUserGroup() {
    try {
      // check if route is restricted by role
      const currentUser = await authentication.getUserInfo();
      const groups = currentUser.signInUserSession.accessToken.payload['cognito:groups'];
      if (roles && roles.map((role) => groups.indexOf(role) == -1).reduce((a, b) => a && b)) {
        // role not authorised so redirect to home page
        redirectToHomePage();
      }
    } catch (e) {
      redirectToHomePage();
    }
  }

  async function checkToken() {
    try {
      const token = await authentication.getAccessToken();
      localStorage.setItem(ACCESS_TOKEN, token);
      if (roles) {
        await checkUserGroup();
      }
    } catch (e) {
      localStorage.removeItem(ACCESS_TOKEN);
      redirectToLogin();
    }
  }

  (async () => {
    await checkToken();
  })();

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem(ACCESS_TOKEN) ? (
          <Route {...props} component={Component} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

export default connect(null, mapDispatchToProps)(PrivateRoute);
