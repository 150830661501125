/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiFile
 */
export interface ApiFile {
    /**
     * 
     * @type {number}
     * @memberof ApiFile
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ApiFile
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof ApiFile
     */
    path: string;
}

export function ApiFileFromJSON(json: any): ApiFile {
    return ApiFileFromJSONTyped(json, false);
}

export function ApiFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiFile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'url': json['url'],
        'path': json['path'],
    };
}

export function ApiFileToJSON(value?: ApiFile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'url': value.url,
        'path': value.path,
    };
}

