/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiCaseType,
    ApiCaseTypeFromJSON,
    ApiCaseTypeFromJSONTyped,
    ApiCaseTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiCaseDetailSection
 */
export interface ApiCaseDetailSection {
    /**
     * 
     * @type {string}
     * @memberof ApiCaseDetailSection
     */
    caseNumber?: string;
    /**
     * 
     * @type {ApiCaseType}
     * @memberof ApiCaseDetailSection
     */
    type?: ApiCaseType;
    /**
     * 
     * @type {Date}
     * @memberof ApiCaseDetailSection
     */
    registrationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApiCaseDetailSection
     */
    serviceName?: string;
}

export function ApiCaseDetailSectionFromJSON(json: any): ApiCaseDetailSection {
    return ApiCaseDetailSectionFromJSONTyped(json, false);
}

export function ApiCaseDetailSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCaseDetailSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'caseNumber': !exists(json, 'caseNumber') ? undefined : json['caseNumber'],
        'type': !exists(json, 'type') ? undefined : ApiCaseTypeFromJSON(json['type']),
        'registrationDate': !exists(json, 'registrationDate') ? undefined : (new Date(json['registrationDate'])),
        'serviceName': !exists(json, 'serviceName') ? undefined : json['serviceName'],
    };
}

export function ApiCaseDetailSectionToJSON(value?: ApiCaseDetailSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'caseNumber': value.caseNumber,
        'type': ApiCaseTypeToJSON(value.type),
        'registrationDate': value.registrationDate === undefined ? undefined : (value.registrationDate.toISOString()),
        'serviceName': value.serviceName,
    };
}

