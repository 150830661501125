import { Dispatch } from 'redux';
import {
  clearCaseDetail,
  setCaseComment,
  setCaseDetail,
  setCaseStatus,
  setProgressNotificationSendingStatus,
} from './caseDetailReducers';
import { ClientError } from '../../common/exceptions/ClientError';
import {
  ApiCaseStatus,
  ApiWorkshopCaseDetail,
  workshopCaseApi,
  workshopCaseFileApi,
  UpdateCaseCommentRequest,
  UpdateCaseProgressNotificationStatusRequest,
} from '../../apis';
import { setSelectedCaseAction } from '../cases/caseActions';
import { CouldnotRetrieveSessionError } from '../../common/exceptions/AuthenticationError';
import { push } from 'connected-react-router';
import { saveAs } from 'file-saver';

export const fetchCaseDetailAction =
  (caseId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const caseDetail: ApiWorkshopCaseDetail = await workshopCaseApi.getCaseDetailsById({
        caseId,
      });
      dispatch(setCaseDetail(caseDetail));
      setSelectedCaseAction(caseId)(dispatch);
    } catch (err) {
      if (err instanceof ClientError) throw err;
      else if (err instanceof CouldnotRetrieveSessionError) {
        dispatch(push('/login?expired=true'));
      } else throw Error('Sorry, something went wrong');
    }
  };

export const removeCaseDetail =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(clearCaseDetail());
  };

export const cancelCaseAction =
  (caseId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await workshopCaseApi.updateCase({
        caseId: caseId,
        apiCaseUpdate: { status: ApiCaseStatus.Canceled },
      });
      dispatch(setCaseStatus(ApiCaseStatus.Canceled));
    } catch (err) {
      if (err instanceof ClientError) throw err;
      else if (err instanceof CouldnotRetrieveSessionError) {
        dispatch(push('/login?expired=true'));
      } else throw Error('Sorry, something went wrong');
    }
  };

export const getCaseImages =
  (caseId: number | undefined) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      if (!caseId) return;
      await workshopCaseFileApi
        .getCaseImages({
          caseId: caseId,
        })
        .then((response) => {
          saveAs(response);
        });
    } catch (err) {
      if (err instanceof ClientError) throw err;
      else if (err instanceof CouldnotRetrieveSessionError) {
        dispatch(push('/login?expired=true'));
      } else throw Error('Sorry, something went wrong');
    }
  };

export const saveCaseCommentAction =
  (caseId: number, comment: string | undefined) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const request: UpdateCaseCommentRequest = {
        caseId: caseId,
        apiCaseComment: { comment: comment },
      };
      await workshopCaseApi.updateCaseComment(request);
      dispatch(setCaseComment({ comment }));
    } catch (err) {
      if (err instanceof ClientError) throw err;
      else if (err instanceof CouldnotRetrieveSessionError) {
        dispatch(push('/login?expired=true'));
      } else throw Error('Sorry, something went wrong');
    }
  };

export const updateCaseProgressNotificationStatusAction =
  (caseId: number, status: boolean) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const request: UpdateCaseProgressNotificationStatusRequest = {
        caseId: caseId,
        apiCaseProgressNotificationStatus: { isProgressNotificationEnabled: status },
      };
      await workshopCaseApi.updateCaseProgressNotificationStatus(request);
      dispatch(setProgressNotificationSendingStatus(status));
    } catch (err) {
      if (err instanceof ClientError) throw err;
      else if (err instanceof CouldnotRetrieveSessionError) {
        dispatch(push('/login?expired=true'));
      } else throw Error('Sorry, something went wrong');
    }
  };
