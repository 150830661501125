import { en } from './languages/en';
import { et } from './languages/et';
import { ru } from './languages/ru';
import { lv } from './languages/lv';
import { Translate } from 'react-redux-i18n';
import React from 'react';

export const translations = {
  en: en,
  et: et,
  ru: ru,
  lv: lv,
};

export const getLocalizedMessageElement = (errorMessage: string | undefined): JSX.Element => {
  return errorMessage ? <Translate value={getErrorMessageKey(errorMessage)} /> : <></>;
};

export const getErrorMessageKey = (errorMessage: string): string => {
  // TODO: change later
  return errorMessage;
};

export const supportedLocales = {
  en: 'English',
  et: 'Estonian',
  ru: 'Russian',
  lv: 'Latvian',
};

export type LanguageType = 'en' | 'et' | 'ru' | 'lv' | undefined;

export const fallbackLocale = 'en';
