/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiVehicleInformationSection
 */
export interface ApiVehicleInformationSection {
    /**
     * 
     * @type {string}
     * @memberof ApiVehicleInformationSection
     */
    licensePlate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVehicleInformationSection
     */
    mark?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVehicleInformationSection
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiVehicleInformationSection
     */
    year?: string;
}

export function ApiVehicleInformationSectionFromJSON(json: any): ApiVehicleInformationSection {
    return ApiVehicleInformationSectionFromJSONTyped(json, false);
}

export function ApiVehicleInformationSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVehicleInformationSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'licensePlate': !exists(json, 'licensePlate') ? undefined : json['licensePlate'],
        'mark': !exists(json, 'mark') ? undefined : json['mark'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'year': !exists(json, 'year') ? undefined : json['year'],
    };
}

export function ApiVehicleInformationSectionToJSON(value?: ApiVehicleInformationSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'licensePlate': value.licensePlate,
        'mark': value.mark,
        'model': value.model,
        'year': value.year,
    };
}

