import React from 'react';
import { Container } from 'react-bootstrap';
import CaseDetailSectionTitle from '../components/CaseDetailSectionTitle';
import CaseDetailSectionField from '../components/CaseDetailSectionField';
import '../CaseSection.css';
import { ApiVehicleInformationSection } from '../../../../api/models';
import { Translate } from 'react-redux-i18n';

export interface VehicleInfoSectionProps {
  vehicleInfoSection: ApiVehicleInformationSection | undefined;
}

export const VehicleInfoSection = ({
  vehicleInfoSection,
}: VehicleInfoSectionProps): JSX.Element => {
  return vehicleInfoSection == undefined ? (
    <></>
  ) : (
    <Container className='claim-section py-3 mt-1 mb-3'>
      <CaseDetailSectionTitle title={<Translate value={'caseDetail.vehicleInfoSection.title'} />} />
      <CaseDetailSectionField
        title={<Translate value={'caseDetail.vehicleInfoSection.licensePlate'} />}
        body={vehicleInfoSection.licensePlate}
      />
      <CaseDetailSectionField
        title={<Translate value={'caseDetail.vehicleInfoSection.mark'} />}
        body={vehicleInfoSection.mark}
      />
      <CaseDetailSectionField
        title={<Translate value={'caseDetail.vehicleInfoSection.model'} />}
        body={vehicleInfoSection.model}
      />
      <CaseDetailSectionField
        title={<Translate value={'caseDetail.vehicleInfoSection.year'} />}
        body={vehicleInfoSection.year}
      />
    </Container>
  );
};

export default VehicleInfoSection;
