import React from 'react';
import { Container } from 'react-bootstrap';
import CaseDetailSectionTitle from '../components/CaseDetailSectionTitle';
import CaseDetailSectionField from '../components/CaseDetailSectionField';
import '../CaseSection.css';
import { ApiCaseDetailSection } from '../../../../api/models';
import { Translate } from 'react-redux-i18n';
import { DateTime, SystemZone } from 'luxon';

export interface CaseDetailSectionProps {
  caseSection: ApiCaseDetailSection | undefined;
}

export const CaseDetailSection = ({ caseSection }: CaseDetailSectionProps): any => {
  return caseSection == undefined ? (
    <></>
  ) : (
    <Container className='claim-section py-3 mt-1 mb-3'>
      <CaseDetailSectionTitle title={<Translate value={'caseDetail.caseDetailSection.title'} />} />
      <CaseDetailSectionField
        title={<Translate value={'caseDetail.caseDetailSection.type'} />}
        body={<Translate value={`caseDetail.caseType.${caseSection.type}`} />}
      />
      <CaseDetailSectionField
        title={<Translate value={'caseDetail.caseDetailSection.registrationDate'} />}
        body={
          caseSection.registrationDate &&
          DateTime.fromJSDate(caseSection.registrationDate, {
            zone: SystemZone.instance,
          }).toFormat('dd/MM/yyyy')
        }
      />
      <CaseDetailSectionField
        title={<Translate value={'caseDetail.caseDetailSection.serviceName'} />}
        body={caseSection.serviceName}
      />
    </Container>
  );
};

export default CaseDetailSection;
