/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiCaseStatus,
    ApiCaseStatusFromJSON,
    ApiCaseStatusFromJSONTyped,
    ApiCaseStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiCaseUpdate
 */
export interface ApiCaseUpdate {
    /**
     * 
     * @type {ApiCaseStatus}
     * @memberof ApiCaseUpdate
     */
    status: ApiCaseStatus;
}

export function ApiCaseUpdateFromJSON(json: any): ApiCaseUpdate {
    return ApiCaseUpdateFromJSONTyped(json, false);
}

export function ApiCaseUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCaseUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': ApiCaseStatusFromJSON(json['status']),
    };
}

export function ApiCaseUpdateToJSON(value?: ApiCaseUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': ApiCaseStatusToJSON(value.status),
    };
}

