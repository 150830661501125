import { ApiWorkshopEmployee, workshopEmployeeApi } from '../../apis';
import authentication from '../../auth/authentication';
import { UserAlreadySignedUpError } from '../../common/exceptions/AuthenticationError';
import { EmployeeNotFoundError } from '../../common/exceptions/EmployeeError';

export const getEmployeeInfoAction = async (
  email: string,
  workshopId: number
): Promise<ApiWorkshopEmployee> => {
  const request = { email: email, workshopId: workshopId };
  try {
    return await workshopEmployeeApi.getEmployeeDetailsWithWorkshopRelation(request);
  } catch (e: any) {
    if (e.status == 404) {
      throw new EmployeeNotFoundError('Employee not found!');
    }
    throw e;
  }
};

export const addEmployeeAction = async (
  credentials: WorkShopEmployeeCreationInfo,
  workShopId: number
): Promise<void> => {
  const request = { apiEmployeeCreationRequest: credentials, id: workShopId };
  return await workshopEmployeeApi.addUserToWorkshop(request);
};

export const signUpToCognitoAction = async (email: string, password: string): Promise<void> => {
  try {
    return await authentication.signUp({
      email: email,
      password: password,
    });
  } catch (e: any) {
    if (!(e instanceof UserAlreadySignedUpError)) {
      throw e;
    }
  }
};

export type WorkShopEmployeeCreationInfo = {
  name: string;
  phone: string;
  email: string;
  password: string;
};

export type WorkShopEmployeeInfo = {
  name: string;
  phone: string;
  email: string;
  isAssignedToWorkshop: boolean;
};
