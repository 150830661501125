import React from 'react';
import Container from 'react-bootstrap/Container';
import './Layout.css';
import Header from '../header/Header';

interface Props {
  children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }: Props) => {
  return (
    <>
      <header>
        <Header />
      </header>
      <Container fluid className='content'>
        {children}
      </Container>
    </>
  );
};

export default Layout;
