/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiCommunicationPreferenceType {
    Email = 'EMAIL',
    Sms = 'SMS'
}

export function ApiCommunicationPreferenceTypeFromJSON(json: any): ApiCommunicationPreferenceType {
    return ApiCommunicationPreferenceTypeFromJSONTyped(json, false);
}

export function ApiCommunicationPreferenceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCommunicationPreferenceType {
    return json as ApiCommunicationPreferenceType;
}

export function ApiCommunicationPreferenceTypeToJSON(value?: ApiCommunicationPreferenceType | null): any {
    return value as any;
}

