/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiCaseType {
    ClaimInsurance = 'CLAIM_INSURANCE',
    RoadsideAssistance = 'ROADSIDE_ASSISTANCE'
}

export function ApiCaseTypeFromJSON(json: any): ApiCaseType {
    return ApiCaseTypeFromJSONTyped(json, false);
}

export function ApiCaseTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCaseType {
    return json as ApiCaseType;
}

export function ApiCaseTypeToJSON(value?: ApiCaseType | null): any {
    return value as any;
}

