/* tslint:disable */
/* eslint-disable */
/**
 * Yaway Workshop API
 * Yaway Workshop API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface GetCaseImagesRequest {
    caseId: number;
}

/**
 * 
 */
export class WorkshopCaseFileApi extends runtime.BaseAPI {

    /**
     * return images associated with case in zip
     */
    async getCaseImagesRaw(requestParameters: GetCaseImagesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.caseId === null || requestParameters.caseId === undefined) {
            throw new runtime.RequiredError('caseId','Required parameter requestParameters.caseId was null or undefined when calling getCaseImages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", ["read", "write"]);
        }

        const response = await this.request({
            path: `/cases/{caseId}/images`.replace(`{${"caseId"}}`, encodeURIComponent(String(requestParameters.caseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * return images associated with case in zip
     */
    async getCaseImages(requestParameters: GetCaseImagesRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.getCaseImagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
