import { Dispatch } from 'redux';
import { loadTranslations, setLocale } from 'react-redux-i18n';
import { fallbackLocale, LanguageType, supportedLocales, translations } from './locale';

export const saveCurrentLanguage =
  (language: LanguageType) =>
  async (dispatch: Dispatch): Promise<void> => {
    setLocaleWithFallback(language as string)(dispatch);
  };

export const setLocaleWithFallback =
  (desiredLocale: string) =>
  (dispatch: Dispatch): void => {
    if (!desiredLocale) return;
    const finalLocale = Object.keys(supportedLocales).includes(desiredLocale)
      ? desiredLocale
      : fallbackLocale;
    // @ts-ignore
    dispatch(setLocale(finalLocale));
  };

export const refreshTranslations =
  () =>
  (dispatch: Dispatch): void => {
    // @ts-ignore
    dispatch(loadTranslations(translations));
  };
