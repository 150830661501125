import { LanguageType } from './LanguageType';

export const ru: LanguageType = {
  errors: {
    tryAgain: 'Извините, что-то пошло не так. Попробуйте еще раз позже.',
    sessionExpiredLoginAgain: 'Время сессии истекло, пожалуйста войдите снова.',
    savingCommentFailed: 'Не удалось сохранить комментарий. Пожалуйста, попробуйте еще раз.',
    savingProgressNotificationStatusFailed:
      'Не удалось сохранить статус сообщения о ходе выполнения. Повторите попытку позже.',
  },
  login: {
    email: 'Эл. почта',
    password: 'Пароль',
    login: 'Войти',
    errors: {
      email: 'Неверный Эл. почта',
      password: 'Неправильный пароль',
    },
  },
  caseList: {
    tab: {
      claims: 'Заявления по страховке',
      assistanceRequests: 'Автомобильная помощь',
    },
    licensePlate: 'Регистрационный номер',
    vehicle: 'Транспортное средство',
    type: 'Тип',
    registrationDate: 'Дата регистрации',
    accidentDate: 'Дата инцидента',
    serviceName: 'Наименование сервиса',
    progress: 'Прогресс',
    noAssistanceCasesFound: 'Нет заявок на aвтомобильная помощь',
    noClaimCasesFound: 'Нет заявлений по страховке',
    toBeEstimated: ' (быть оцененным)',
  },
  caseDetail: {
    caseType: {
      CLAIM_INSURANCE: 'Заявления по страховке',
      ROADSIDE_ASSISTANCE: 'Автомобильная помощь',
    },
    caseStatus: {
      PENDING: 'В ОЖИДАНИИ',
      CANCELED: 'ОТМЕНЕНО',
      COMPLETED: 'ЗАВЕРШЕНО',
    },
    cancelCase: {
      button: 'Oтменить Заявку',
      modalTitle: 'Oтменить Заявку',
      modalBody: 'Вы уверены, что хотите отменить этот случай?',
      yes: 'ДА',
      no: 'НЕТ',
    },
    caseDetailSection: {
      title: 'ДЕТАЛИ СЛУЧАЯ',
      type: 'ТИП',
      registrationDate: 'ДАТА РЕГИСТРАЦИИ',
      serviceName: 'НАИМЕНОВАНИЕ СЕРВИСА',
    },
    vehicleInfoSection: {
      title: 'ИНФОРМАЦИЯ О ТРАНСПОРТНОМ СРЕДСТВЕ',
      licensePlate: 'РЕГИСТРАЦИОННЫЙ НОМЕР',
      mark: 'МАРКА',
      model: 'МОЕДЕЛЬ',
      year: 'ГОД',
    },
    notifierInfoSection: {
      title: 'ИНФОРМАЦИЯ УВЕДОМИТЕЛЯ/ВОДИТЕЛЯ',
      name: 'ИМЯ',
      phone: 'НОМЕР ТЕЛЕФОНА',
      email: 'ЭЛ. ПОЧТА',
      personalCode: 'ПЕРСОНАЛЬНЫЙ КОД',
      driverName: 'ИМЯ ВОДИТЕЛЯ',
      driverPhone: 'НОМЕР ТЕЛЕФОНА ВОДИТЕЛЯ',
      driverEmail: 'ЭЛ. ПОЧТА ВОДИТЕЛЯ',
      driverPersonalCode: 'ПЕРСОНАЛЬНЫЙ КОД ВОДИТЕЛЯ',
    },
    communicationPreference: {
      label: 'ПРЕДПОЧИТАЕМЫЙ СПОСОБ СВЯЗИ',
      email: 'ЭЛ. ПОЧТА',
      sms: 'SMS',
      emailAndSms: 'ЭЛ. ПОЧТА & SMS',
    },
    accidentDetailsSection: {
      title: 'ДЕТАЛИ ПРОИШЕСТВИЯ',
      incidentType: 'ТИП ПРОИШЕСТВИЯ',
      date: 'ДАТА И ВРЕМЯ',
      location: 'МЕСТО',
      description: 'ОПИСАНИЕ',
    },
    otherPartiesSection: {
      title: 'ДРУГИЕ СТОРОНЫ (ОБЪЕКТЫ)',
      description: 'ОПИСАНИЕ',
      name: 'ИМЯ',
      personalCode: 'ПЕРСОНАЛЬНЫЙ КОД',
      phone: 'ТЕЛЕФОН',
      email: 'ЭЛ. ПОЧТА',
    },
    infoTab: {
      claims: 'Заявки',
      assistance: 'Автомобильная помощь',
    },
    documentsSection: {
      title: 'ДОКУМЕНТЫ',
    },
    photos: 'ФОТОГРАФИИ',
    downloadAll: 'Скачать все',
    caseProgress: {
      title: 'ПРОГРЕСС ЗАЯВКИ',
      markCompleted: 'Отметить завершенным',
      change: 'Изменить дату',
    },
    noData: 'Нет данных',
    commentsSection: {
      title: 'КОММЕНТАРИИ',
      save: 'Сохранить',
      commentBoxPlaceholder: 'Введите свой комментарий и нажмите сохранить',
    },
    caseSettings: {
      button: 'Настройки',
      progressNotifications: {
        label: 'Отправка сообщений о ходе выполнения',
      },
    },
  },
  sendSmsLinkModal: {
    claimLinkModalTitle: 'Отправить ссылку на заявление по СМС',
    assistanceLinkModalTitle: 'Отправьте ссылку для вызова помощи через SMS',
    licensePlateLabel: 'Введите регистрационный номер',
    phoneNumberLabel: 'Введите номер телефона',
    serviceSelectorLabel: 'Выберите сервис',
    sendSms: 'Отправить СМС',
    claimLinkSentSuccessfully: 'Ссылка на заявление успешно отправлена',
    assistanceLinkSentSuccessfully: 'Ссылка для вызова помощи успешно отправлена',
    ifLinkWasntSentMessage:
      'Если по какой-то причине вы не получили ссылку, попробуйте отправить еще раз.',
    continue: 'ПРОДОЛЖИТЬ',
  },
  header: {
    searchText: 'Поиск по рестрационному номеру',
  },
  common: {
    required: 'Обязательное поле!',
  },
  caseStatus: {
    title: 'Статус заявки',
    registration: '№ регистрации.',
    policyInfo: 'Информация о страховом полисе',
    steps: {
      caseRegistered: 'Страховой случай зарегистрирован',
      caseRegisteredDone: 'Страховой случай зарегистрирован',
      vehicleDeliveredToWorkshop: 'Автомобиль доставлен в мастерскую',
      vehicleDeliveredToWorkshopDone: 'Автомобиль доставлен в мастерскую',
      estimatedDiagnostics: 'Ожидаемая диагностика',
      estimatedDiagnosticsDone: 'Диагностика завершена',
      preparingRepairEstimate:
        'Подготовка к составлению калькуляции для устранения повреждений / по ремонту',
      preparingRepairEstimateDone: 'Составление калькуляции завершено',
      confirmingRepairEstimate: 'Подтверждение калькуляции по ремонту',
      confirmingRepairEstimateDone: 'Калькуляция подтверждена',
      estimatedArrivalOfSpareParts: 'Ожидаемое время получения запчастей',
      estimatedArrivalOfSparePartsDone: 'Запчасти получены',
      repairingStarts: 'Начало ремонта',
      repairingStartsDone: 'Ремонт начат',
      estimatedEndOfRepair: 'Предпологаемое время окончания ремонта',
      estimatedEndOfRepairDone: 'Ремонт завершен',
      pickUpVehicle: 'Транспортное средство готово к выдаче',
      pickUpVehicleDone: 'Транспортное средство выдано',
    },
    nextStep: 'Вашим делом занимаются. Теперь следующий шаг',
    errors: {
      invalidOrExpiredLink: 'Ссылка не действительна или устарела',
    },
  },
  incidentType: {
    WINDSHIELD_DAMAGE: 'Повреждение лобового стекла',
    TRAFFIC_ACCIDENT: 'Дорожно-транспортное происшествие',
    HIT_WILD_ANIMAL: 'Сбил животное',
    TECHNICAL_BREAKDOWN: 'Техническая неполадка',
    THEFT: 'Угон',
    VANDALISM_OR_FIRE: 'Вандализм или пожар',
    OTHER: 'Другое',
    EMPTY_BATTERY: 'Разряженная батарея',
    RUN_OUT_OF_FUEL: 'Закончилось топливо',
    FLAT_TIRES: 'Спущенная шина',
    STUCK_IN_SAND_SNOW_MUD: 'Застрял в песке/снегу/грязи',
    DOOR_LOCKED: 'Двери заблокированы',
    UNKNOWN_TECHNICAL_ISSUE: 'Неизвестная техническая проблема',
  },
  pagination: {
    rowsPerPage: 'Строк на страницу',
  },
  logout: 'Выйти',
  filters: {
    search: 'Поиск',
    filterByProgressStep: 'Фильтровать по шагу выполнения',
  },
  addEmployee: {
    name: 'Полное имя',
    phone: 'Телефонный номер',
    email: 'Эл. почта',
    password: 'Пароль',
    header: 'Добавить сотрудника',
    saveEmployee: 'Сохранить',
    workshop: 'мастерская',
    success:
      'Сотрудник успешно добавлен, и ему/ей отправлено электронное письмо с подтверждением. После подтверждения сотрудник сможет авторизоваться.',
    errors: {
      email: 'Неверный Эл. почта',
      phone: 'Неправильный номер телефона',
      password: 'неправильный пароль',
      name: 'Неправильное имя',
      alreadyAssigned: 'Указанный пользователь уже добавлен в эту мастерскую.',
    },
  },
  refreshPageToApplyChangesPopup: {
    refresh: 'Обновить',
    refreshPageToSeeUpdates:
      'Мы обновили страницу. Пожалуйста, обновите страницу, чтобы увидеть последние изменения.',
  },
  smsLinkSelector: {
    smsLink: 'SMS ссылка',
    claimLink: 'Заявление по страховке',
    assistanceLink: 'Тех.помощь на дороге',
  },
};
