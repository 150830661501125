import React from 'react';
import { Container } from 'react-bootstrap';
import CaseDetailSectionTitle from '../components/CaseDetailSectionTitle';
import CaseDetailSectionField from '../components/CaseDetailSectionField';
import '../CaseSection.css';
import { ApiAccidentDetailsSection } from '../../../../api/models';
import { Translate } from 'react-redux-i18n';
import { DateTime, SystemZone } from 'luxon';

export interface AccidentDetailsSectionProps {
  accidentDetailsSection: ApiAccidentDetailsSection | undefined;
}

export const AccidentDetailsSection = ({
  accidentDetailsSection,
}: AccidentDetailsSectionProps): JSX.Element => {
  return accidentDetailsSection == undefined ? (
    <></>
  ) : (
    <Container className='claim-section py-3 mt-1 mb-3'>
      <CaseDetailSectionTitle
        title={<Translate value={'caseDetail.accidentDetailsSection.title'} />}
      />
      {accidentDetailsSection.incidentType && (
        <CaseDetailSectionField
          title={<Translate value={'caseDetail.accidentDetailsSection.incidentType'} />}
          body={<Translate value={`incidentType.${accidentDetailsSection?.incidentType}`} />}
        />
      )}
      <CaseDetailSectionField
        title={<Translate value={'caseDetail.accidentDetailsSection.date'} />}
        body={
          accidentDetailsSection.dateAndTime &&
          DateTime.fromJSDate(accidentDetailsSection.dateAndTime, {
            zone: SystemZone.instance,
          }).toFormat('dd/MM/yyyy HH:mm')
        }
      />
      <CaseDetailSectionField
        title={<Translate value={'caseDetail.accidentDetailsSection.location'} />}
        body={accidentDetailsSection.location}
      />
      <CaseDetailSectionField
        title={<Translate value={'caseDetail.accidentDetailsSection.description'} />}
        body={accidentDetailsSection.description}
      />
    </Container>
  );
};

export default AccidentDetailsSection;
