import Amplify from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';

export const configureAmplify = (): void => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: 'eu-central-1',
      userPoolId: 'eu-central-1_AdRrWG3h9',
      userPoolWebClientId: '62td9buutqd16psljb7t1ti8c8',
    },
    API: {
      endpoints: [
        {
          name: 'Backend',
          endpoint: '/api/v1',
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getAccessToken()
                .getJwtToken()}`,
            };
          },
        },
      ],
    },
  });
};
